import React, { Component } from 'react';
import {connect} from "react-redux"
import ToggleFullscreen from "../Common/ToggleFullscreen"

class Footer extends Component {

    render() {
        return (
            <footer className="footer-container">
                { /* Fullscreen (only desktops) */ }
                <div className="float-right" style={{paddingRight: this.props.offsidebarOpen ? "235px" : "0"}}>
                    <ToggleFullscreen/>
                </div>
            </footer>
        );
    }

}

const mapStateToProps = state => ({ offsidebarOpen: state.settings.offsidebarOpen, })

export default connect(
    mapStateToProps,
    null,
)(Footer);
