import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, ListGroup, ListGroupItem } from 'reactstrap';
import { push } from 'connected-react-router'

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../../store/actions/actions';
import {history} from "../../store/store"
import { ActiveCharts } from '../../RealTimeSubscription'

import HeaderRun from './Header.run'
import RelevantPopup from "./RelevantPopup"
import EventBus from '../../EventBus';

import { userHasFullAccess } from '../../AuthenticationUtil';
import { setItem as setSettingsItem, getItem as getSettingsItem } from '../../SettingsUtil';

class Header extends Component {
    previousLocation = '';

    constructor(props) {
        super(props)
        this.handleCategories = this.handleCategories.bind(this)
        this.handleMainChart = this.handleMainChart.bind(this)
        this.createChartTitle = this.createChartTitle.bind(this)
        this.onRelevantClicked = this.onRelevantClicked.bind(this)
        this.onSearchSymbolChanged = this.onSearchSymbolChanged.bind(this)
        this.state = {
            showRelevant: false,
            currentSymbol: this.props.settings.currentSymbol,
            editedSearchString: (this.props.settings.currentSymbol || {}).quote
        }
    }

    componentDidMount() {
        //HeaderRun();
    }

    toggleUserblock = e => {
        e.preventDefault();
        this.props.actions.toggleSetting('showUserBlock');
    }

    handleSettingsNavigation = e => {
        e.preventDefault();
        const location = this.props.router.location.pathname;
        if (location.indexOf('settings') !== -1) {
            this.props.dispatch(push(this.previousLocation));
        } else {
            this.previousLocation = location;
            this.props.dispatch(push('settings'));
        }
    }

    toggleOffsidebar = e => {
        e.preventDefault()
        this.props.actions.toggleSetting('offsidebarOpen');
    }

    toggleCollapsed = e => {
        e.preventDefault()
        this.props.actions.toggleSetting('isCollapsed');
        this.resize()
    }

    toggleAside = e => {
        e.preventDefault()
        this.props.actions.toggleSetting('asideToggled');
    }

    handleCategories = target  => {
        const val  = `/${target.target.value}`;
        setSettingsItem('currentDashboard', target.target.value);
        this.props.dispatch(push(val));

        this.setState(this.state);
    }

    handleMainChart = target => {
        const val = target.target.value;
        setSettingsItem("mainDashboardPeriod", val);
        EventBus.dispatch("localStorageUpdated", "mainDashboardPeriod");

        this.setState(this.state);
    }

    createChartTitle = chartObj => {
        return `${chartObj.value} ${chartObj.unit.toLowerCase()}${chartObj.value > 1 ? 's' : ''}`;
    }

    resize () {
        // all IE friendly dispatchEvent
        var evt = document.createEvent('UIEvents');
        evt.initUIEvent('resize', true, false, window, 0);
        window.dispatchEvent(evt);
        // modern dispatchEvent way
        // window.dispatchEvent(new Event('resize'));
    }

    onRelevantClicked = (item) => {
        if (!item)
        {
            item = this.state.currentSymbol
        }
        this.props.actions.changeSetting("currentSymbol", item)

        const { currentDashboard, mainDashboardPeriod } = this.getCurrentDashboardAndPeriod();

        if (
            (!this.state.currentSymbol || this.state.currentSymbol.category !== "EquityNonUS") &&
            item.category === "EquityNonUS"
        )
        {
            const chartIndex = ActiveCharts.findIndex(el => el.step === mainDashboardPeriod);
            if (chartIndex !== -1 && chartIndex < 32) {
                this.handleMainChart({ target: { value: 't1day' } });
            }

            const belowOneDay = [
                "1Minute-30Minutes",
                "45Minutes-8Hours",
                "9Hours-18Hours",
                "19Hours-3Days",
                "intraday-1",
                "intraday-2",
                "daily-1",
                "daily-2",
                "2-day",
            ];
            if (belowOneDay.indexOf(currentDashboard) !== -1) {
                this.handleCategories({ target: { value: '4Days-12Days' } });
            }
        } else if (this.state.currentSymbol && this.state.currentSymbol.category === "EquityNonUS" && item.category !== "EquityNonUS") {
            this.handleMainChart({ target: { value: mainDashboardPeriod } });
            this.handleCategories({ target: { value: currentDashboard } });
        }
        this.setState({...this.state, currentSymbol: item, editedSearchString: item.quote, showRelevant: false})
    }

    onSearchSymbolChanged = (v) => {
        const val = v.currentTarget.value
        this.setState({...this.state, showRelevant: false, editedSearchString: val})
        if (this.onSearchTimout)
            clearTimeout(this.onSearchTimout)
        if (!val)
            return
        this.onSearchTimout = setTimeout(()=> {
            this.setState({...this.state, showRelevant: true, editedSearchString: val})
        }, 1000)
    }

    render() {
        const hasFullAccess = userHasFullAccess();
        const subDaySymbols = !this.state.currentSymbol || this.state.currentSymbol.category !== "EquityNonUS";
        const isChartEnabled = (_, index) => {
            if (subDaySymbols)
                return true;
            return index >= 32;
        }
        const { currentDashboard, mainDashboardPeriod } = this.getCurrentDashboardAndPeriod();
        return (
            <header className="topnavbar-wrapper">
                { /* START Top Navbar */ }
                <nav className="navbar topnavbar">
                    { /* START navbar header */ }
                    <div className="navbar-header">
                        <a className="navbar-brand" href="#/">
                            <div className="brand-logo">
                                <img className="img-fluid" src="img/logo-single.png" alt="Fincharts" />
                            </div>
                            <div className="brand-logo-collapsed">
                                <img className="img-fluid" src="img/logo-single.png" alt="Fincharts" />
                            </div>
                        </a>
                    </div>
                    { /* END navbar header */ }

                    { /* Search Symbol header*/}
                    <div className="float-left">
                        <input className="form-control"
                               onChange={this.onSearchSymbolChanged}
                               value={this.state.editedSearchString}
                               type="text"/>
                    </div>
                    
                    <ul className="navbar-nav">
                        <li className="nav-item">
                            { /* <a className="nav-link" href="" data-search-open="">
                                <em className="icon-magnifier"></em>
                            </a>
                            */ }
                            { '\u00A0' }
                            { '\u00A0' }
                            { '\u00A0' }
                        </li>
                    </ul>

                    {this.state.showRelevant && <RelevantPopup onClick={this.onRelevantClicked} searchString={this.state.editedSearchString}/>}

                    { /* START Left navbar */ }
                    <div className="float-left">
                        <select defaultValue={currentDashboard}
                                className="custom-select"
                                id="dashboardSelector"
                                onChange={this.handleCategories}
                        >
                            <option value={"Main"}>Main</option>
                            <option value="" disabled>—————————————</option>
                            <option value={"1Minute-30Minutes"} disabled={!hasFullAccess || !subDaySymbols} hidden={!subDaySymbols}>1 minute - 30 minutes</option>
                            <option value={"45Minutes-8Hours"} disabled={!hasFullAccess || !subDaySymbols} hidden={!subDaySymbols}>45 minutes - 8 hours</option>
                            <option value={"9Hours-18Hours"} disabled={!hasFullAccess || !subDaySymbols} hidden={!subDaySymbols}>9 hours - 18 hours</option>
                            <option value={"19Hours-3Days"} disabled={!hasFullAccess || !subDaySymbols} hidden={!subDaySymbols}>19 hours - 3 days</option>
                            <option value={"4Days-12Days"} disabled={!hasFullAccess}>4 days - 12 days</option>
                            <option value={"13Days-22Days"} disabled={!hasFullAccess}>13 days - 22 days</option>
                            <option value={"23Days-1-and-a-half-Months"} disabled={!hasFullAccess}>23 days - 1.5 months</option>
                            <option value={"2Months-10Months"} disabled={!hasFullAccess}>2 months - 10 months</option>
                            <option value={"11Months-2Years"} disabled={!hasFullAccess}>11 months - 2 years</option>
                            <option value="" disabled>—————————————</option>
                            <option value={"intraday-1"} disabled={!hasFullAccess || !subDaySymbols} hidden={!subDaySymbols}>Intraday 1</option>
                            <option value={"intraday-2"} disabled={!hasFullAccess || !subDaySymbols} hidden={!subDaySymbols}>Intraday 2</option>
                            <option value={"daily-1"} disabled={!hasFullAccess || !subDaySymbols} hidden={!subDaySymbols}>Daily 1</option>
                            <option value={"daily-2"} disabled={!hasFullAccess || !subDaySymbols} hidden={!subDaySymbols}>Daily 2</option>
                            <option value={"2-day"} disabled={!hasFullAccess || !subDaySymbols} hidden={!subDaySymbols}>2 Day</option>
                            <option value={"weekly"} disabled={!hasFullAccess}>Weekly</option>
                            <option value={"monthly-1"} disabled={!hasFullAccess}>Monthly 1</option>
                            <option value={"monthly-2"} disabled={!hasFullAccess}>Monthly 2</option>
                            <option value={"mid-term-1"} disabled={!hasFullAccess}>Mid Term 1</option>
                            <option value={"mid-term-2"} disabled={!hasFullAccess}>Mid Term 2</option>
                            <option value={"long-term"} disabled={!hasFullAccess}>Long Term</option>
                        </select>
                    </div>
                    <div className="float-left" style={{marginLeft: '10px', display: currentDashboard === 'Main' ? 'block' : 'none'}}>
                        <select defaultValue={mainDashboardPeriod}
                                className="custom-select"
                                id="mainChartSelector"
                                onChange={this.handleMainChart}
                        >
                            {
                                ActiveCharts.map((el, index) => {
                                    const isEnabled = isChartEnabled(el, index);
                                    return <option value={el.step} disabled={!isEnabled} hidden={!isEnabled}>{ this.createChartTitle(el) }</option>
                                })
                            }
                        </select>
                    </div>
                    <ul className="navbar-nav mr-auto flex-row">

                        { /* START User avatar toggle */ }
                        { /*
                        <li className="nav-item d-none d-md-block">
                            <a  className="nav-link" onClick={ this.toggleUserblock }>
                                <em className="icon-user"></em>
                            </a>
                        </li>
                        */ }
                        { /* END User avatar toggle */ }
                        { /* START lock screen */ }
                        { /*
                        <li className="nav-item d-none d-md-block">
                            <Link to="lock" title="Lock screen" className="nav-link">
                                <em className="icon-lock"></em>
                            </Link>
                        </li>
                        */ }
                        { /* END lock screen */ }
                    </ul>
                    { /* END Left navbar */ }
                    { /* START Right Navbar */ }
                    <ul className="navbar-nav flex-row">
                        { /* Search icon */ }
                        { /*
                        <li className="nav-item">
                            <a className="nav-link" href="" data-search-open="">
                                <em className="icon-magnifier"></em>
                            </a>
                        </li>
                        */ }

                        { /* START Alert menu */ }
                        <UncontrolledDropdown nav inNavbar className="dropdown-list">
                            { /* 
                            <DropdownToggle nav className="dropdown-toggle-nocaret">
                                <em className="icon-bell"></em>
                                <span className="badge badge-danger">11</span>
                            </DropdownToggle>
                            */ }
                            { /* START Dropdown menu */ }
                            <DropdownMenu right className="dropdown-menu-right animated flipInX">
                                <DropdownItem>
                                    { /* START list group */ }
                                    <ListGroup>
                                       <ListGroupItem action tag="a" href="" onClick={e => e.preventDefault()}>
                                          <div className="media">
                                             <div className="align-self-start mr-2">
                                                <em className="fab fa-twitter fa-2x text-info"></em>
                                             </div>
                                             <div className="media-body">
                                                <p className="m-0">New followers</p>
                                                <p className="m-0 text-muted text-sm">1 new follower</p>
                                             </div>
                                          </div>
                                       </ListGroupItem>
                                       <ListGroupItem action tag="a" href="" onClick={e => e.preventDefault()}>
                                          <div className="media">
                                             <div className="align-self-start mr-2">
                                                <em className="fa fa-envelope fa-2x text-warning"></em>
                                             </div>
                                             <div className="media-body">
                                                <p className="m-0">New e-mails</p>
                                                <p className="m-0 text-muted text-sm">You have 10 new emails</p>
                                             </div>
                                          </div>
                                       </ListGroupItem>
                                       <ListGroupItem action tag="a" href="" onClick={e => e.preventDefault()}>
                                          <div className="media">
                                             <div className="align-self-start mr-2">
                                                <em className="fa fa-tasks fa-2x text-success"></em>
                                             </div>
                                             <div className="media-body">
                                                <p className="m-0">Pending Tasks</p>
                                                <p className="m-0 text-muted text-sm">11 pending task</p>
                                             </div>
                                          </div>
                                       </ListGroupItem>
                                       <ListGroupItem action tag="a" href="" onClick={e => e.preventDefault()}>
                                          <span className="d-flex align-items-center">
                                             <span className="text-sm">More notifications</span>
                                             <span className="badge badge-danger ml-auto">14</span>
                                          </span>
                                       </ListGroupItem>
                                    </ListGroup>
                                    { /* END list group */ }
                                </DropdownItem>
                            </DropdownMenu>
                            { /* END Dropdown menu */ }
                        </UncontrolledDropdown>
                        { /* END Alert menu */ }
                        { /* START Offsidebar button */ }
                        <li className="nav-item">
                            <a className="nav-link" href="" onClick={this.handleSettingsNavigation}>
                                <em style={{color: "#999999"}} className="icon-settings"></em>
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="" onClick={this.toggleOffsidebar}>
                                <em style={{color: "#999999"}} className="icon-menu"></em>
                            </a>
                        </li>
                        { /* END Offsidebar menu */ }
                    </ul>
                    { /* END Right Navbar */ }

                    { /* START Search form */ }
                    <form className="navbar-form" role="search" action="search.html">
                       <div className="form-group">
                          <input className="form-control" type="text" placeholder="Type and hit enter ..."/>
                          <div className="fa fa-times navbar-form-close" data-search-dismiss=""></div>
                       </div>
                       <button className="d-none" type="submit">Submit</button>
                    </form>
                    { /* END Search form */ }
                </nav>
                { /* END Top Navbar */ }

            </header>
        );
    }

    getCurrentDashboardAndPeriod() {
        const currentDashboardLocation = this.props.router.location.pathname.replace("/", "");
        const currentDashboard = currentDashboardLocation ? currentDashboardLocation : 'Main';
        const persistedPeriod = getSettingsItem("mainDashboardPeriod");
        const mainDashboardPeriod = persistedPeriod ? persistedPeriod : "t1day";
        return { currentDashboard, mainDashboardPeriod };
    }
}

Header.propTypes = {
    actions: PropTypes.object,
    settings: PropTypes.object
};

const mapStateToProps = state => ({
    settings: state.settings,
    router: state.router
})
const mapDispatchToProps = dispatch => ({dispatch, actions: bindActionCreators(actions, dispatch) })

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Header);