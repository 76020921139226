import * as axios from "axios"

import RealTimeSubscription from './RealTimeSubscription';

const STORAGE_FIELD_NAME = "authToken";
const STORAGE_FIELD_PRODUCT_NAME = "activeProduct";
const CHECK_SESSION_TIMER_INTERVAL = 10 * 60 * 1000;

const FULL_ACCESS_PRODUCT_IDS = [47, 59, 61];

let authUrlBase = "";
let timerId = undefined;

const ELAPSED_THRESHOLD = 30 * 60 * 1000; // 30 minutes
let elapsedTime;
let logoffTimerId = undefined;
const resetElapsedTime = () => {
    elapsedTime = new Date();
}

export const SESSION_EXPIRED_SIGNIFIER = 'sesexp';

export function getToken() {
    let item = localStorage.getItem(STORAGE_FIELD_NAME);
    if (item)
        return item;
    return sessionStorage.getItem(STORAGE_FIELD_NAME);
}

export function saveToken(token, rememberMe) {
    if (rememberMe)
        localStorage.setItem(STORAGE_FIELD_NAME, token);
    else
        sessionStorage.setItem(STORAGE_FIELD_NAME, token);
}

export function getActiveProduct() {
    return sessionStorage.getItem(STORAGE_FIELD_PRODUCT_NAME);
}

export function saveActiveProduct(activeProduct) {
    sessionStorage.setItem(STORAGE_FIELD_PRODUCT_NAME, activeProduct);
}

export function clearActiveProduct() {
    sessionStorage.removeItem(STORAGE_FIELD_PRODUCT_NAME);
}

export function logout() {
    sessionStorage.removeItem(STORAGE_FIELD_NAME);
    localStorage.removeItem(STORAGE_FIELD_NAME);
    RealTimeSubscription.terminate();
}

export function isAuthenticated() {
    return Boolean(getToken());
};

export function setupCheckSession(authUrl) {
    if (timerId === undefined) {
        timerId = setInterval(checkSession, CHECK_SESSION_TIMER_INTERVAL);
        authUrlBase = authUrl;
        getSubscriptions(authUrlBase);
    }
}

export function cancelCheckSession() {
    if (timerId !== undefined) {
        clearInterval(timerId);
        timerId = undefined;
    }
}

export function setupAutoLogoff() {
    elapsedTime = new Date();

    document.addEventListener("mousemove", resetElapsedTime, false);
    document.addEventListener("mousedown", resetElapsedTime, false);
    document.addEventListener("keypress", resetElapsedTime, false);
    document.addEventListener("touchmove", resetElapsedTime, false);
    document.addEventListener("onscroll", resetElapsedTime, false);
    
    logoffTimerId = window.setInterval(function() {
        if (new Date().getTime() - elapsedTime.getTime() >= ELAPSED_THRESHOLD) {
            window.location.href = '/logout#' + SESSION_EXPIRED_SIGNIFIER;
        }
    }, 5 * 1000); // check each 5 seconds
}

export function cancelAutoLogoff() {
    if (logoffTimerId !== undefined) {
        clearInterval(logoffTimerId);
        logoffTimerId = undefined;

        document.removeEventListener("mousemove", resetElapsedTime, false);
        document.removeEventListener("mousedown", resetElapsedTime, false);
        document.removeEventListener("keypress", resetElapsedTime, false);
        document.removeEventListener("touchmove", resetElapsedTime, false);
        document.removeEventListener("onscroll", resetElapsedTime, false);
    }
}

function checkSession() {
    const token = getToken();
    const logoutProc = () => {
        logout();
        window.location.href = '/login';
    };
    if (!token) {
        logoutProc();
        return;
    }
    axios({
        method: "GET",
        url: `${authUrlBase}validate-session?session=${token}`,
    })
    .then(() => {
        getSubscriptions(authUrlBase);
    })
    .catch(()=>{
        logoutProc();
    });
}

export function getSubscriptions(url, callback, errorCallback) {
    const token = getToken();
    axios({
        method: "GET",
        url: `${url}subscriptions?session=${token}`,
    })
    .then((res) => {
        const products = res.data.products;
        let activeProduct = undefined;
        if (products && products.length > 0) {
            activeProduct = products[0];
        }
        if (activeProduct !== undefined)
            saveActiveProduct(activeProduct);
        else
            clearActiveProduct();
        if (callback)
            callback();
    })
    .catch(()=>{
        if (errorCallback)
            errorCallback();
    });
}

export function userHasFullAccess() {
    const activeProduct = getActiveProduct();
    return Boolean(FULL_ACCESS_PRODUCT_IDS.find(el => el == activeProduct));
}

export function userHasPartialAccess() {
    const activeProduct = getActiveProduct();
    return Boolean(activeProduct);
}