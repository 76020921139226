import { combineReducers } from 'redux'
import {connectRouter} from 'connected-react-router'

import settingsReducer from './settings.reducer.js'
import themesReducer from './themes.reducers.js'
import popoutReducer from './popout.reducer.js'

export default (history) => combineReducers({
    router: connectRouter(history),
    popout: popoutReducer,
    settings: settingsReducer,
    theme: themesReducer
});
