import { createStore, applyMiddleware } from 'redux'
import {createBrowserHistory} from 'history'
import reducers from './reducers/reducers'
import {routerMiddleware} from 'connected-react-router'
import middlewares from './middlewares/middlewares'

import { updateTheme } from './middlewares/themes.middleware.js';

import { persistedState, saveState } from './persisted.store.js';

export const history = createBrowserHistory()

export default function configureStore() {

    const store = createStore(
        reducers(history),
        persistedState, // second argument overrides the initial state
        applyMiddleware(
            routerMiddleware(history),
            ...middlewares
        )
    );

    // add a listener that will be invoked on any state change
    store.subscribe(() => {
        saveState(store.getState());
    });

    // Update the initial theme
    updateTheme(store.getState())

    return store;

}