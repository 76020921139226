import * as React from "react"
import * as axios from "axios"

class RelevantPopup extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            relevants: []
        }
        this.handleClickOutside = this.handleClickOutside.bind(this)
        this.updateSymbols = this.updateSymbols.bind(this)
        this.updateSymbols()
    }

    updateSymbols() {
        const url = process.envs.SYMBOL_LOOKUP_REST_API
        axios({
            method: "GET",
            url: `${url}/${encodeURIComponent(this.props.searchString)}?count=30&crypto_count=10&forex_count=10&equity_us_count=7&equity_non_us_count=3`
        })
            .then((res) => {
                const quotes = res.data.map(q => ({...q, quote: q.id.replace("<=>", "/"), fullName: q.name}))
                console.log(quotes)
                if (this.__mounted)
                    this.setState({...this.state, relevants: quotes})
            })
            .catch((err)=>{
                console.log(err)
            })
    }

    componentDidMount() {
        this.__mounted = true
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        this.__mounted = false
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    handleClickOutside(e) {
        const path = e.path || (e.composedPath && e.composedPath())
        const clickedInside = path.some(p => {
            return p.id === "relPopup"
        })
        if (!clickedInside)
            this.props.onClick()
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.searchString !== this.props.searchString) {
            if (this.props.searchString)
                this.updateSymbols()
            this.setState({...this.state, relevants: []})
        }
    }


    render() {
        return (
            <div id="relPopup" className="card" style={{position: "absolute", top: 55, left: 70}}>
                <div className="list-group">
                    {
                        this.state.relevants.map(item =>
                            <a className="list-group-item list-group-item-action"
                               key={item.quote}
                               onClick={this.props.onClick.bind(null, item)}
                            >
                                <b>{item.quote}</b> - {item.fullName}
                            </a>)
                    }
                </div>
            </div>
        )
    }
}

export default RelevantPopup