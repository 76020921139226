import * as $protobuf from "protobufjs/minimal";

// Common aliases
const $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
const $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

export const fincharts = $root.fincharts = (() => {

    /**
     * Namespace fincharts.
     * @exports fincharts
     * @namespace
     */
    const fincharts = {};

    fincharts.AuthentificateRequest = (function() {

        /**
         * Properties of an AuthentificateRequest.
         * @memberof fincharts
         * @interface IAuthentificateRequest
         * @property {string|null} [token] AuthentificateRequest token
         */

        /**
         * Constructs a new AuthentificateRequest.
         * @memberof fincharts
         * @classdesc Represents an AuthentificateRequest.
         * @implements IAuthentificateRequest
         * @constructor
         * @param {fincharts.IAuthentificateRequest=} [properties] Properties to set
         */
        function AuthentificateRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AuthentificateRequest token.
         * @member {string} token
         * @memberof fincharts.AuthentificateRequest
         * @instance
         */
        AuthentificateRequest.prototype.token = "";

        /**
         * Creates a new AuthentificateRequest instance using the specified properties.
         * @function create
         * @memberof fincharts.AuthentificateRequest
         * @static
         * @param {fincharts.IAuthentificateRequest=} [properties] Properties to set
         * @returns {fincharts.AuthentificateRequest} AuthentificateRequest instance
         */
        AuthentificateRequest.create = function create(properties) {
            return new AuthentificateRequest(properties);
        };

        /**
         * Encodes the specified AuthentificateRequest message. Does not implicitly {@link fincharts.AuthentificateRequest.verify|verify} messages.
         * @function encode
         * @memberof fincharts.AuthentificateRequest
         * @static
         * @param {fincharts.IAuthentificateRequest} message AuthentificateRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AuthentificateRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.token != null && Object.hasOwnProperty.call(message, "token"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.token);
            return writer;
        };

        /**
         * Encodes the specified AuthentificateRequest message, length delimited. Does not implicitly {@link fincharts.AuthentificateRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof fincharts.AuthentificateRequest
         * @static
         * @param {fincharts.IAuthentificateRequest} message AuthentificateRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AuthentificateRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AuthentificateRequest message from the specified reader or buffer.
         * @function decode
         * @memberof fincharts.AuthentificateRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {fincharts.AuthentificateRequest} AuthentificateRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AuthentificateRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.fincharts.AuthentificateRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.token = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an AuthentificateRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof fincharts.AuthentificateRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {fincharts.AuthentificateRequest} AuthentificateRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AuthentificateRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AuthentificateRequest message.
         * @function verify
         * @memberof fincharts.AuthentificateRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AuthentificateRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.token != null && message.hasOwnProperty("token"))
                if (!$util.isString(message.token))
                    return "token: string expected";
            return null;
        };

        /**
         * Creates an AuthentificateRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof fincharts.AuthentificateRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {fincharts.AuthentificateRequest} AuthentificateRequest
         */
        AuthentificateRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.fincharts.AuthentificateRequest)
                return object;
            let message = new $root.fincharts.AuthentificateRequest();
            if (object.token != null)
                message.token = String(object.token);
            return message;
        };

        /**
         * Creates a plain object from an AuthentificateRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof fincharts.AuthentificateRequest
         * @static
         * @param {fincharts.AuthentificateRequest} message AuthentificateRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AuthentificateRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.token = "";
            if (message.token != null && message.hasOwnProperty("token"))
                object.token = message.token;
            return object;
        };

        /**
         * Converts this AuthentificateRequest to JSON.
         * @function toJSON
         * @memberof fincharts.AuthentificateRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AuthentificateRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return AuthentificateRequest;
    })();

    fincharts.IndicatorRequest = (function() {

        /**
         * Properties of an IndicatorRequest.
         * @memberof fincharts
         * @interface IIndicatorRequest
         * @property {string|null} [name] IndicatorRequest name
         * @property {Object.<string,string>|null} [params] IndicatorRequest params
         */

        /**
         * Constructs a new IndicatorRequest.
         * @memberof fincharts
         * @classdesc Represents an IndicatorRequest.
         * @implements IIndicatorRequest
         * @constructor
         * @param {fincharts.IIndicatorRequest=} [properties] Properties to set
         */
        function IndicatorRequest(properties) {
            this.params = {};
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * IndicatorRequest name.
         * @member {string} name
         * @memberof fincharts.IndicatorRequest
         * @instance
         */
        IndicatorRequest.prototype.name = "";

        /**
         * IndicatorRequest params.
         * @member {Object.<string,string>} params
         * @memberof fincharts.IndicatorRequest
         * @instance
         */
        IndicatorRequest.prototype.params = $util.emptyObject;

        /**
         * Creates a new IndicatorRequest instance using the specified properties.
         * @function create
         * @memberof fincharts.IndicatorRequest
         * @static
         * @param {fincharts.IIndicatorRequest=} [properties] Properties to set
         * @returns {fincharts.IndicatorRequest} IndicatorRequest instance
         */
        IndicatorRequest.create = function create(properties) {
            return new IndicatorRequest(properties);
        };

        /**
         * Encodes the specified IndicatorRequest message. Does not implicitly {@link fincharts.IndicatorRequest.verify|verify} messages.
         * @function encode
         * @memberof fincharts.IndicatorRequest
         * @static
         * @param {fincharts.IIndicatorRequest} message IndicatorRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        IndicatorRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
            if (message.params != null && Object.hasOwnProperty.call(message, "params"))
                for (let keys = Object.keys(message.params), i = 0; i < keys.length; ++i)
                    writer.uint32(/* id 2, wireType 2 =*/18).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]).uint32(/* id 2, wireType 2 =*/18).string(message.params[keys[i]]).ldelim();
            return writer;
        };

        /**
         * Encodes the specified IndicatorRequest message, length delimited. Does not implicitly {@link fincharts.IndicatorRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof fincharts.IndicatorRequest
         * @static
         * @param {fincharts.IIndicatorRequest} message IndicatorRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        IndicatorRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an IndicatorRequest message from the specified reader or buffer.
         * @function decode
         * @memberof fincharts.IndicatorRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {fincharts.IndicatorRequest} IndicatorRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        IndicatorRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.fincharts.IndicatorRequest(), key, value;
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.name = reader.string();
                    break;
                case 2:
                    if (message.params === $util.emptyObject)
                        message.params = {};
                    let end2 = reader.uint32() + reader.pos;
                    key = "";
                    value = "";
                    while (reader.pos < end2) {
                        let tag2 = reader.uint32();
                        switch (tag2 >>> 3) {
                        case 1:
                            key = reader.string();
                            break;
                        case 2:
                            value = reader.string();
                            break;
                        default:
                            reader.skipType(tag2 & 7);
                            break;
                        }
                    }
                    message.params[key] = value;
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an IndicatorRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof fincharts.IndicatorRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {fincharts.IndicatorRequest} IndicatorRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        IndicatorRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an IndicatorRequest message.
         * @function verify
         * @memberof fincharts.IndicatorRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        IndicatorRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.name != null && message.hasOwnProperty("name"))
                if (!$util.isString(message.name))
                    return "name: string expected";
            if (message.params != null && message.hasOwnProperty("params")) {
                if (!$util.isObject(message.params))
                    return "params: object expected";
                let key = Object.keys(message.params);
                for (let i = 0; i < key.length; ++i)
                    if (!$util.isString(message.params[key[i]]))
                        return "params: string{k:string} expected";
            }
            return null;
        };

        /**
         * Creates an IndicatorRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof fincharts.IndicatorRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {fincharts.IndicatorRequest} IndicatorRequest
         */
        IndicatorRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.fincharts.IndicatorRequest)
                return object;
            let message = new $root.fincharts.IndicatorRequest();
            if (object.name != null)
                message.name = String(object.name);
            if (object.params) {
                if (typeof object.params !== "object")
                    throw TypeError(".fincharts.IndicatorRequest.params: object expected");
                message.params = {};
                for (let keys = Object.keys(object.params), i = 0; i < keys.length; ++i)
                    message.params[keys[i]] = String(object.params[keys[i]]);
            }
            return message;
        };

        /**
         * Creates a plain object from an IndicatorRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof fincharts.IndicatorRequest
         * @static
         * @param {fincharts.IndicatorRequest} message IndicatorRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        IndicatorRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.objects || options.defaults)
                object.params = {};
            if (options.defaults)
                object.name = "";
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            let keys2;
            if (message.params && (keys2 = Object.keys(message.params)).length) {
                object.params = {};
                for (let j = 0; j < keys2.length; ++j)
                    object.params[keys2[j]] = message.params[keys2[j]];
            }
            return object;
        };

        /**
         * Converts this IndicatorRequest to JSON.
         * @function toJSON
         * @memberof fincharts.IndicatorRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        IndicatorRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return IndicatorRequest;
    })();

    fincharts.StrategyRequest = (function() {

        /**
         * Properties of a StrategyRequest.
         * @memberof fincharts
         * @interface IStrategyRequest
         * @property {string|null} [name] StrategyRequest name
         * @property {Object.<string,string>|null} [params] StrategyRequest params
         */

        /**
         * Constructs a new StrategyRequest.
         * @memberof fincharts
         * @classdesc Represents a StrategyRequest.
         * @implements IStrategyRequest
         * @constructor
         * @param {fincharts.IStrategyRequest=} [properties] Properties to set
         */
        function StrategyRequest(properties) {
            this.params = {};
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * StrategyRequest name.
         * @member {string} name
         * @memberof fincharts.StrategyRequest
         * @instance
         */
        StrategyRequest.prototype.name = "";

        /**
         * StrategyRequest params.
         * @member {Object.<string,string>} params
         * @memberof fincharts.StrategyRequest
         * @instance
         */
        StrategyRequest.prototype.params = $util.emptyObject;

        /**
         * Creates a new StrategyRequest instance using the specified properties.
         * @function create
         * @memberof fincharts.StrategyRequest
         * @static
         * @param {fincharts.IStrategyRequest=} [properties] Properties to set
         * @returns {fincharts.StrategyRequest} StrategyRequest instance
         */
        StrategyRequest.create = function create(properties) {
            return new StrategyRequest(properties);
        };

        /**
         * Encodes the specified StrategyRequest message. Does not implicitly {@link fincharts.StrategyRequest.verify|verify} messages.
         * @function encode
         * @memberof fincharts.StrategyRequest
         * @static
         * @param {fincharts.IStrategyRequest} message StrategyRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StrategyRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
            if (message.params != null && Object.hasOwnProperty.call(message, "params"))
                for (let keys = Object.keys(message.params), i = 0; i < keys.length; ++i)
                    writer.uint32(/* id 2, wireType 2 =*/18).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]).uint32(/* id 2, wireType 2 =*/18).string(message.params[keys[i]]).ldelim();
            return writer;
        };

        /**
         * Encodes the specified StrategyRequest message, length delimited. Does not implicitly {@link fincharts.StrategyRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof fincharts.StrategyRequest
         * @static
         * @param {fincharts.IStrategyRequest} message StrategyRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StrategyRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a StrategyRequest message from the specified reader or buffer.
         * @function decode
         * @memberof fincharts.StrategyRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {fincharts.StrategyRequest} StrategyRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StrategyRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.fincharts.StrategyRequest(), key, value;
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.name = reader.string();
                    break;
                case 2:
                    if (message.params === $util.emptyObject)
                        message.params = {};
                    let end2 = reader.uint32() + reader.pos;
                    key = "";
                    value = "";
                    while (reader.pos < end2) {
                        let tag2 = reader.uint32();
                        switch (tag2 >>> 3) {
                        case 1:
                            key = reader.string();
                            break;
                        case 2:
                            value = reader.string();
                            break;
                        default:
                            reader.skipType(tag2 & 7);
                            break;
                        }
                    }
                    message.params[key] = value;
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a StrategyRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof fincharts.StrategyRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {fincharts.StrategyRequest} StrategyRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StrategyRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a StrategyRequest message.
         * @function verify
         * @memberof fincharts.StrategyRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        StrategyRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.name != null && message.hasOwnProperty("name"))
                if (!$util.isString(message.name))
                    return "name: string expected";
            if (message.params != null && message.hasOwnProperty("params")) {
                if (!$util.isObject(message.params))
                    return "params: object expected";
                let key = Object.keys(message.params);
                for (let i = 0; i < key.length; ++i)
                    if (!$util.isString(message.params[key[i]]))
                        return "params: string{k:string} expected";
            }
            return null;
        };

        /**
         * Creates a StrategyRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof fincharts.StrategyRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {fincharts.StrategyRequest} StrategyRequest
         */
        StrategyRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.fincharts.StrategyRequest)
                return object;
            let message = new $root.fincharts.StrategyRequest();
            if (object.name != null)
                message.name = String(object.name);
            if (object.params) {
                if (typeof object.params !== "object")
                    throw TypeError(".fincharts.StrategyRequest.params: object expected");
                message.params = {};
                for (let keys = Object.keys(object.params), i = 0; i < keys.length; ++i)
                    message.params[keys[i]] = String(object.params[keys[i]]);
            }
            return message;
        };

        /**
         * Creates a plain object from a StrategyRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof fincharts.StrategyRequest
         * @static
         * @param {fincharts.StrategyRequest} message StrategyRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        StrategyRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.objects || options.defaults)
                object.params = {};
            if (options.defaults)
                object.name = "";
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            let keys2;
            if (message.params && (keys2 = Object.keys(message.params)).length) {
                object.params = {};
                for (let j = 0; j < keys2.length; ++j)
                    object.params[keys2[j]] = message.params[keys2[j]];
            }
            return object;
        };

        /**
         * Converts this StrategyRequest to JSON.
         * @function toJSON
         * @memberof fincharts.StrategyRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        StrategyRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return StrategyRequest;
    })();

    fincharts.Subscribe = (function() {

        /**
         * Properties of a Subscribe.
         * @memberof fincharts
         * @interface ISubscribe
         * @property {fincharts.ISymbol|null} [symbol] Subscribe symbol
         * @property {fincharts.TimeStep|null} [step] Subscribe step
         * @property {Array.<fincharts.IIndicatorRequest>|null} [indicators] Subscribe indicators
         * @property {fincharts.IStrategyRequest|null} [strategy] Subscribe strategy
         */

        /**
         * Constructs a new Subscribe.
         * @memberof fincharts
         * @classdesc Represents a Subscribe.
         * @implements ISubscribe
         * @constructor
         * @param {fincharts.ISubscribe=} [properties] Properties to set
         */
        function Subscribe(properties) {
            this.indicators = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Subscribe symbol.
         * @member {fincharts.ISymbol|null|undefined} symbol
         * @memberof fincharts.Subscribe
         * @instance
         */
        Subscribe.prototype.symbol = null;

        /**
         * Subscribe step.
         * @member {fincharts.TimeStep} step
         * @memberof fincharts.Subscribe
         * @instance
         */
        Subscribe.prototype.step = 0;

        /**
         * Subscribe indicators.
         * @member {Array.<fincharts.IIndicatorRequest>} indicators
         * @memberof fincharts.Subscribe
         * @instance
         */
        Subscribe.prototype.indicators = $util.emptyArray;

        /**
         * Subscribe strategy.
         * @member {fincharts.IStrategyRequest|null|undefined} strategy
         * @memberof fincharts.Subscribe
         * @instance
         */
        Subscribe.prototype.strategy = null;

        /**
         * Creates a new Subscribe instance using the specified properties.
         * @function create
         * @memberof fincharts.Subscribe
         * @static
         * @param {fincharts.ISubscribe=} [properties] Properties to set
         * @returns {fincharts.Subscribe} Subscribe instance
         */
        Subscribe.create = function create(properties) {
            return new Subscribe(properties);
        };

        /**
         * Encodes the specified Subscribe message. Does not implicitly {@link fincharts.Subscribe.verify|verify} messages.
         * @function encode
         * @memberof fincharts.Subscribe
         * @static
         * @param {fincharts.ISubscribe} message Subscribe message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Subscribe.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.symbol != null && Object.hasOwnProperty.call(message, "symbol"))
                $root.fincharts.Symbol.encode(message.symbol, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.step != null && Object.hasOwnProperty.call(message, "step"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.step);
            if (message.indicators != null && message.indicators.length)
                for (let i = 0; i < message.indicators.length; ++i)
                    $root.fincharts.IndicatorRequest.encode(message.indicators[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.strategy != null && Object.hasOwnProperty.call(message, "strategy"))
                $root.fincharts.StrategyRequest.encode(message.strategy, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified Subscribe message, length delimited. Does not implicitly {@link fincharts.Subscribe.verify|verify} messages.
         * @function encodeDelimited
         * @memberof fincharts.Subscribe
         * @static
         * @param {fincharts.ISubscribe} message Subscribe message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Subscribe.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Subscribe message from the specified reader or buffer.
         * @function decode
         * @memberof fincharts.Subscribe
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {fincharts.Subscribe} Subscribe
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Subscribe.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.fincharts.Subscribe();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.symbol = $root.fincharts.Symbol.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.step = reader.int32();
                    break;
                case 3:
                    if (!(message.indicators && message.indicators.length))
                        message.indicators = [];
                    message.indicators.push($root.fincharts.IndicatorRequest.decode(reader, reader.uint32()));
                    break;
                case 4:
                    message.strategy = $root.fincharts.StrategyRequest.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Subscribe message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof fincharts.Subscribe
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {fincharts.Subscribe} Subscribe
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Subscribe.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Subscribe message.
         * @function verify
         * @memberof fincharts.Subscribe
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Subscribe.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.symbol != null && message.hasOwnProperty("symbol")) {
                let error = $root.fincharts.Symbol.verify(message.symbol);
                if (error)
                    return "symbol." + error;
            }
            if (message.step != null && message.hasOwnProperty("step"))
                switch (message.step) {
                default:
                    return "step: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                case 5:
                case 6:
                case 7:
                case 8:
                case 9:
                case 10:
                case 11:
                case 12:
                case 13:
                case 14:
                case 15:
                case 16:
                case 17:
                case 18:
                case 19:
                case 20:
                case 21:
                case 22:
                case 23:
                case 24:
                case 25:
                case 26:
                case 27:
                case 28:
                case 29:
                case 30:
                case 31:
                case 32:
                case 33:
                case 34:
                case 35:
                case 36:
                case 37:
                case 38:
                case 39:
                case 40:
                case 41:
                case 42:
                case 43:
                case 44:
                case 45:
                case 46:
                case 47:
                case 48:
                case 49:
                case 50:
                case 51:
                case 52:
                case 53:
                case 54:
                case 55:
                case 56:
                case 57:
                case 58:
                case 59:
                case 60:
                case 61:
                case 62:
                case 63:
                case 64:
                case 65:
                case 66:
                case 67:
                case 68:
                case 69:
                case 70:
                case 71:
                case 72:
                case 73:
                case 74:
                case 75:
                case 76:
                case 77:
                case 78:
                case 79:
                case 80:
                case 81:
                case 82:
                case 83:
                case 84:
                case 85:
                case 86:
                case 87:
                case 88:
                case 89:
                    break;
                }
            if (message.indicators != null && message.hasOwnProperty("indicators")) {
                if (!Array.isArray(message.indicators))
                    return "indicators: array expected";
                for (let i = 0; i < message.indicators.length; ++i) {
                    let error = $root.fincharts.IndicatorRequest.verify(message.indicators[i]);
                    if (error)
                        return "indicators." + error;
                }
            }
            if (message.strategy != null && message.hasOwnProperty("strategy")) {
                let error = $root.fincharts.StrategyRequest.verify(message.strategy);
                if (error)
                    return "strategy." + error;
            }
            return null;
        };

        /**
         * Creates a Subscribe message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof fincharts.Subscribe
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {fincharts.Subscribe} Subscribe
         */
        Subscribe.fromObject = function fromObject(object) {
            if (object instanceof $root.fincharts.Subscribe)
                return object;
            let message = new $root.fincharts.Subscribe();
            if (object.symbol != null) {
                if (typeof object.symbol !== "object")
                    throw TypeError(".fincharts.Subscribe.symbol: object expected");
                message.symbol = $root.fincharts.Symbol.fromObject(object.symbol);
            }
            switch (object.step) {
            case "t1sec":
            case 0:
                message.step = 0;
                break;
            case "t5sec":
            case 1:
                message.step = 1;
                break;
            case "t15sec":
            case 2:
                message.step = 2;
                break;
            case "t30sec":
            case 3:
                message.step = 3;
                break;
            case "t1min":
            case 4:
                message.step = 4;
                break;
            case "t2min":
            case 5:
                message.step = 5;
                break;
            case "t3min":
            case 6:
                message.step = 6;
                break;
            case "t4min":
            case 7:
                message.step = 7;
                break;
            case "t5min":
            case 8:
                message.step = 8;
                break;
            case "t10min":
            case 9:
                message.step = 9;
                break;
            case "t15min":
            case 10:
                message.step = 10;
                break;
            case "t20min":
            case 11:
                message.step = 11;
                break;
            case "t25min":
            case 12:
                message.step = 12;
                break;
            case "t30min":
            case 13:
                message.step = 13;
                break;
            case "t45min":
            case 14:
                message.step = 14;
                break;
            case "t1h":
            case 15:
                message.step = 15;
                break;
            case "t90min":
            case 16:
                message.step = 16;
                break;
            case "t2h":
            case 17:
                message.step = 17;
                break;
            case "t3h":
            case 18:
                message.step = 18;
                break;
            case "t4h":
            case 19:
                message.step = 19;
                break;
            case "t5h":
            case 20:
                message.step = 20;
                break;
            case "t6h":
            case 21:
                message.step = 21;
                break;
            case "t7h":
            case 22:
                message.step = 22;
                break;
            case "t8h":
            case 23:
                message.step = 23;
                break;
            case "t9h":
            case 24:
                message.step = 24;
                break;
            case "t10h":
            case 25:
                message.step = 25;
                break;
            case "t11h":
            case 26:
                message.step = 26;
                break;
            case "t12h":
            case 27:
                message.step = 27;
                break;
            case "t13h":
            case 28:
                message.step = 28;
                break;
            case "t14h":
            case 29:
                message.step = 29;
                break;
            case "t15h":
            case 30:
                message.step = 30;
                break;
            case "t16h":
            case 31:
                message.step = 31;
                break;
            case "t17h":
            case 32:
                message.step = 32;
                break;
            case "t18h":
            case 33:
                message.step = 33;
                break;
            case "t19h":
            case 34:
                message.step = 34;
                break;
            case "t20h":
            case 35:
                message.step = 35;
                break;
            case "t21h":
            case 36:
                message.step = 36;
                break;
            case "t22h":
            case 37:
                message.step = 37;
                break;
            case "t23h":
            case 38:
                message.step = 38;
                break;
            case "t1day":
            case 39:
                message.step = 39;
                break;
            case "t30h":
            case 40:
                message.step = 40;
                break;
            case "t36h":
            case 41:
                message.step = 41;
                break;
            case "t42h":
            case 42:
                message.step = 42;
                break;
            case "t2day":
            case 43:
                message.step = 43;
                break;
            case "t60h":
            case 44:
                message.step = 44;
                break;
            case "t3day":
            case 45:
                message.step = 45;
                break;
            case "t4day":
            case 46:
                message.step = 46;
                break;
            case "t5day":
            case 47:
                message.step = 47;
                break;
            case "t6day":
            case 48:
                message.step = 48;
                break;
            case "t1week":
            case 49:
                message.step = 49;
                break;
            case "t7day":
            case 50:
                message.step = 50;
                break;
            case "t8day":
            case 51:
                message.step = 51;
                break;
            case "t9day":
            case 52:
                message.step = 52;
                break;
            case "t10day":
            case 53:
                message.step = 53;
                break;
            case "t11day":
            case 54:
                message.step = 54;
                break;
            case "t12day":
            case 55:
                message.step = 55;
                break;
            case "t13day":
            case 56:
                message.step = 56;
                break;
            case "t14day":
            case 57:
                message.step = 57;
                break;
            case "t15day":
            case 58:
                message.step = 58;
                break;
            case "t16day":
            case 59:
                message.step = 59;
                break;
            case "t17day":
            case 60:
                message.step = 60;
                break;
            case "t18day":
            case 61:
                message.step = 61;
                break;
            case "t19day":
            case 62:
                message.step = 62;
                break;
            case "t20day":
            case 63:
                message.step = 63;
                break;
            case "t21day":
            case 64:
                message.step = 64;
                break;
            case "t22day":
            case 65:
                message.step = 65;
                break;
            case "t23day":
            case 66:
                message.step = 66;
                break;
            case "t24day":
            case 67:
                message.step = 67;
                break;
            case "t25day":
            case 68:
                message.step = 68;
                break;
            case "t26day":
            case 69:
                message.step = 69;
                break;
            case "t27day":
            case 70:
                message.step = 70;
                break;
            case "t28day":
            case 71:
                message.step = 71;
                break;
            case "t29day":
            case 72:
                message.step = 72;
                break;
            case "t30day":
            case 73:
                message.step = 73;
                break;
            case "t1m":
            case 74:
                message.step = 74;
                break;
            case "t45day":
            case 75:
                message.step = 75;
                break;
            case "t60day":
            case 76:
                message.step = 76;
                break;
            case "t75day":
            case 77:
                message.step = 77;
                break;
            case "t3m":
            case 78:
                message.step = 78;
                break;
            case "t120day":
            case 79:
                message.step = 79;
                break;
            case "t150day":
            case 80:
                message.step = 80;
                break;
            case "t6m":
            case 81:
                message.step = 81;
                break;
            case "t210day":
            case 82:
                message.step = 82;
                break;
            case "t240day":
            case 83:
                message.step = 83;
                break;
            case "t270day":
            case 84:
                message.step = 84;
                break;
            case "t300day":
            case 85:
                message.step = 85;
                break;
            case "t330day":
            case 86:
                message.step = 86;
                break;
            case "t1y":
            case 87:
                message.step = 87;
                break;
            case "t18m":
            case 88:
                message.step = 88;
                break;
            case "t2y":
            case 89:
                message.step = 89;
                break;
            }
            if (object.indicators) {
                if (!Array.isArray(object.indicators))
                    throw TypeError(".fincharts.Subscribe.indicators: array expected");
                message.indicators = [];
                for (let i = 0; i < object.indicators.length; ++i) {
                    if (typeof object.indicators[i] !== "object")
                        throw TypeError(".fincharts.Subscribe.indicators: object expected");
                    message.indicators[i] = $root.fincharts.IndicatorRequest.fromObject(object.indicators[i]);
                }
            }
            if (object.strategy != null) {
                if (typeof object.strategy !== "object")
                    throw TypeError(".fincharts.Subscribe.strategy: object expected");
                message.strategy = $root.fincharts.StrategyRequest.fromObject(object.strategy);
            }
            return message;
        };

        /**
         * Creates a plain object from a Subscribe message. Also converts values to other types if specified.
         * @function toObject
         * @memberof fincharts.Subscribe
         * @static
         * @param {fincharts.Subscribe} message Subscribe
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Subscribe.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.indicators = [];
            if (options.defaults) {
                object.symbol = null;
                object.step = options.enums === String ? "t1sec" : 0;
                object.strategy = null;
            }
            if (message.symbol != null && message.hasOwnProperty("symbol"))
                object.symbol = $root.fincharts.Symbol.toObject(message.symbol, options);
            if (message.step != null && message.hasOwnProperty("step"))
                object.step = options.enums === String ? $root.fincharts.TimeStep[message.step] : message.step;
            if (message.indicators && message.indicators.length) {
                object.indicators = [];
                for (let j = 0; j < message.indicators.length; ++j)
                    object.indicators[j] = $root.fincharts.IndicatorRequest.toObject(message.indicators[j], options);
            }
            if (message.strategy != null && message.hasOwnProperty("strategy"))
                object.strategy = $root.fincharts.StrategyRequest.toObject(message.strategy, options);
            return object;
        };

        /**
         * Converts this Subscribe to JSON.
         * @function toJSON
         * @memberof fincharts.Subscribe
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Subscribe.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return Subscribe;
    })();

    fincharts.Cancel = (function() {

        /**
         * Properties of a Cancel.
         * @memberof fincharts
         * @interface ICancel
         * @property {number|Long|null} [ID] Cancel ID
         */

        /**
         * Constructs a new Cancel.
         * @memberof fincharts
         * @classdesc Represents a Cancel.
         * @implements ICancel
         * @constructor
         * @param {fincharts.ICancel=} [properties] Properties to set
         */
        function Cancel(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Cancel ID.
         * @member {number|Long} ID
         * @memberof fincharts.Cancel
         * @instance
         */
        Cancel.prototype.ID = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * Creates a new Cancel instance using the specified properties.
         * @function create
         * @memberof fincharts.Cancel
         * @static
         * @param {fincharts.ICancel=} [properties] Properties to set
         * @returns {fincharts.Cancel} Cancel instance
         */
        Cancel.create = function create(properties) {
            return new Cancel(properties);
        };

        /**
         * Encodes the specified Cancel message. Does not implicitly {@link fincharts.Cancel.verify|verify} messages.
         * @function encode
         * @memberof fincharts.Cancel
         * @static
         * @param {fincharts.ICancel} message Cancel message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Cancel.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.ID != null && Object.hasOwnProperty.call(message, "ID"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.ID);
            return writer;
        };

        /**
         * Encodes the specified Cancel message, length delimited. Does not implicitly {@link fincharts.Cancel.verify|verify} messages.
         * @function encodeDelimited
         * @memberof fincharts.Cancel
         * @static
         * @param {fincharts.ICancel} message Cancel message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Cancel.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Cancel message from the specified reader or buffer.
         * @function decode
         * @memberof fincharts.Cancel
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {fincharts.Cancel} Cancel
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Cancel.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.fincharts.Cancel();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.ID = reader.uint64();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Cancel message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof fincharts.Cancel
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {fincharts.Cancel} Cancel
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Cancel.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Cancel message.
         * @function verify
         * @memberof fincharts.Cancel
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Cancel.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.ID != null && message.hasOwnProperty("ID"))
                if (!$util.isInteger(message.ID) && !(message.ID && $util.isInteger(message.ID.low) && $util.isInteger(message.ID.high)))
                    return "ID: integer|Long expected";
            return null;
        };

        /**
         * Creates a Cancel message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof fincharts.Cancel
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {fincharts.Cancel} Cancel
         */
        Cancel.fromObject = function fromObject(object) {
            if (object instanceof $root.fincharts.Cancel)
                return object;
            let message = new $root.fincharts.Cancel();
            if (object.ID != null)
                if ($util.Long)
                    (message.ID = $util.Long.fromValue(object.ID)).unsigned = true;
                else if (typeof object.ID === "string")
                    message.ID = parseInt(object.ID, 10);
                else if (typeof object.ID === "number")
                    message.ID = object.ID;
                else if (typeof object.ID === "object")
                    message.ID = new $util.LongBits(object.ID.low >>> 0, object.ID.high >>> 0).toNumber(true);
            return message;
        };

        /**
         * Creates a plain object from a Cancel message. Also converts values to other types if specified.
         * @function toObject
         * @memberof fincharts.Cancel
         * @static
         * @param {fincharts.Cancel} message Cancel
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Cancel.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.ID = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.ID = options.longs === String ? "0" : 0;
            if (message.ID != null && message.hasOwnProperty("ID"))
                if (typeof message.ID === "number")
                    object.ID = options.longs === String ? String(message.ID) : message.ID;
                else
                    object.ID = options.longs === String ? $util.Long.prototype.toString.call(message.ID) : options.longs === Number ? new $util.LongBits(message.ID.low >>> 0, message.ID.high >>> 0).toNumber(true) : message.ID;
            return object;
        };

        /**
         * Converts this Cancel to JSON.
         * @function toJSON
         * @memberof fincharts.Cancel
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Cancel.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return Cancel;
    })();

    fincharts.GetHistoricalData = (function() {

        /**
         * Properties of a GetHistoricalData.
         * @memberof fincharts
         * @interface IGetHistoricalData
         * @property {fincharts.ISymbol|null} [symbol] GetHistoricalData symbol
         * @property {fincharts.TimeStep|null} [step] GetHistoricalData step
         * @property {google.protobuf.ITimestamp|null} [begin] GetHistoricalData begin
         * @property {google.protobuf.ITimestamp|null} [end] GetHistoricalData end
         * @property {Array.<fincharts.IIndicatorRequest>|null} [indicators] GetHistoricalData indicators
         * @property {fincharts.IStrategyRequest|null} [strategy] GetHistoricalData strategy
         */

        /**
         * Constructs a new GetHistoricalData.
         * @memberof fincharts
         * @classdesc Represents a GetHistoricalData.
         * @implements IGetHistoricalData
         * @constructor
         * @param {fincharts.IGetHistoricalData=} [properties] Properties to set
         */
        function GetHistoricalData(properties) {
            this.indicators = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetHistoricalData symbol.
         * @member {fincharts.ISymbol|null|undefined} symbol
         * @memberof fincharts.GetHistoricalData
         * @instance
         */
        GetHistoricalData.prototype.symbol = null;

        /**
         * GetHistoricalData step.
         * @member {fincharts.TimeStep} step
         * @memberof fincharts.GetHistoricalData
         * @instance
         */
        GetHistoricalData.prototype.step = 0;

        /**
         * GetHistoricalData begin.
         * @member {google.protobuf.ITimestamp|null|undefined} begin
         * @memberof fincharts.GetHistoricalData
         * @instance
         */
        GetHistoricalData.prototype.begin = null;

        /**
         * GetHistoricalData end.
         * @member {google.protobuf.ITimestamp|null|undefined} end
         * @memberof fincharts.GetHistoricalData
         * @instance
         */
        GetHistoricalData.prototype.end = null;

        /**
         * GetHistoricalData indicators.
         * @member {Array.<fincharts.IIndicatorRequest>} indicators
         * @memberof fincharts.GetHistoricalData
         * @instance
         */
        GetHistoricalData.prototype.indicators = $util.emptyArray;

        /**
         * GetHistoricalData strategy.
         * @member {fincharts.IStrategyRequest|null|undefined} strategy
         * @memberof fincharts.GetHistoricalData
         * @instance
         */
        GetHistoricalData.prototype.strategy = null;

        /**
         * Creates a new GetHistoricalData instance using the specified properties.
         * @function create
         * @memberof fincharts.GetHistoricalData
         * @static
         * @param {fincharts.IGetHistoricalData=} [properties] Properties to set
         * @returns {fincharts.GetHistoricalData} GetHistoricalData instance
         */
        GetHistoricalData.create = function create(properties) {
            return new GetHistoricalData(properties);
        };

        /**
         * Encodes the specified GetHistoricalData message. Does not implicitly {@link fincharts.GetHistoricalData.verify|verify} messages.
         * @function encode
         * @memberof fincharts.GetHistoricalData
         * @static
         * @param {fincharts.IGetHistoricalData} message GetHistoricalData message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetHistoricalData.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.symbol != null && Object.hasOwnProperty.call(message, "symbol"))
                $root.fincharts.Symbol.encode(message.symbol, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.step != null && Object.hasOwnProperty.call(message, "step"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.step);
            if (message.begin != null && Object.hasOwnProperty.call(message, "begin"))
                $root.google.protobuf.Timestamp.encode(message.begin, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.end != null && Object.hasOwnProperty.call(message, "end"))
                $root.google.protobuf.Timestamp.encode(message.end, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.indicators != null && message.indicators.length)
                for (let i = 0; i < message.indicators.length; ++i)
                    $root.fincharts.IndicatorRequest.encode(message.indicators[i], writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            if (message.strategy != null && Object.hasOwnProperty.call(message, "strategy"))
                $root.fincharts.StrategyRequest.encode(message.strategy, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified GetHistoricalData message, length delimited. Does not implicitly {@link fincharts.GetHistoricalData.verify|verify} messages.
         * @function encodeDelimited
         * @memberof fincharts.GetHistoricalData
         * @static
         * @param {fincharts.IGetHistoricalData} message GetHistoricalData message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetHistoricalData.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GetHistoricalData message from the specified reader or buffer.
         * @function decode
         * @memberof fincharts.GetHistoricalData
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {fincharts.GetHistoricalData} GetHistoricalData
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetHistoricalData.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.fincharts.GetHistoricalData();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.symbol = $root.fincharts.Symbol.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.step = reader.int32();
                    break;
                case 3:
                    message.begin = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.end = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                    break;
                case 5:
                    if (!(message.indicators && message.indicators.length))
                        message.indicators = [];
                    message.indicators.push($root.fincharts.IndicatorRequest.decode(reader, reader.uint32()));
                    break;
                case 6:
                    message.strategy = $root.fincharts.StrategyRequest.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GetHistoricalData message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof fincharts.GetHistoricalData
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {fincharts.GetHistoricalData} GetHistoricalData
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetHistoricalData.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GetHistoricalData message.
         * @function verify
         * @memberof fincharts.GetHistoricalData
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GetHistoricalData.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.symbol != null && message.hasOwnProperty("symbol")) {
                let error = $root.fincharts.Symbol.verify(message.symbol);
                if (error)
                    return "symbol." + error;
            }
            if (message.step != null && message.hasOwnProperty("step"))
                switch (message.step) {
                default:
                    return "step: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                case 5:
                case 6:
                case 7:
                case 8:
                case 9:
                case 10:
                case 11:
                case 12:
                case 13:
                case 14:
                case 15:
                case 16:
                case 17:
                case 18:
                case 19:
                case 20:
                case 21:
                case 22:
                case 23:
                case 24:
                case 25:
                case 26:
                case 27:
                case 28:
                case 29:
                case 30:
                case 31:
                case 32:
                case 33:
                case 34:
                case 35:
                case 36:
                case 37:
                case 38:
                case 39:
                case 40:
                case 41:
                case 42:
                case 43:
                case 44:
                case 45:
                case 46:
                case 47:
                case 48:
                case 49:
                case 50:
                case 51:
                case 52:
                case 53:
                case 54:
                case 55:
                case 56:
                case 57:
                case 58:
                case 59:
                case 60:
                case 61:
                case 62:
                case 63:
                case 64:
                case 65:
                case 66:
                case 67:
                case 68:
                case 69:
                case 70:
                case 71:
                case 72:
                case 73:
                case 74:
                case 75:
                case 76:
                case 77:
                case 78:
                case 79:
                case 80:
                case 81:
                case 82:
                case 83:
                case 84:
                case 85:
                case 86:
                case 87:
                case 88:
                case 89:
                    break;
                }
            if (message.begin != null && message.hasOwnProperty("begin")) {
                let error = $root.google.protobuf.Timestamp.verify(message.begin);
                if (error)
                    return "begin." + error;
            }
            if (message.end != null && message.hasOwnProperty("end")) {
                let error = $root.google.protobuf.Timestamp.verify(message.end);
                if (error)
                    return "end." + error;
            }
            if (message.indicators != null && message.hasOwnProperty("indicators")) {
                if (!Array.isArray(message.indicators))
                    return "indicators: array expected";
                for (let i = 0; i < message.indicators.length; ++i) {
                    let error = $root.fincharts.IndicatorRequest.verify(message.indicators[i]);
                    if (error)
                        return "indicators." + error;
                }
            }
            if (message.strategy != null && message.hasOwnProperty("strategy")) {
                let error = $root.fincharts.StrategyRequest.verify(message.strategy);
                if (error)
                    return "strategy." + error;
            }
            return null;
        };

        /**
         * Creates a GetHistoricalData message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof fincharts.GetHistoricalData
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {fincharts.GetHistoricalData} GetHistoricalData
         */
        GetHistoricalData.fromObject = function fromObject(object) {
            if (object instanceof $root.fincharts.GetHistoricalData)
                return object;
            let message = new $root.fincharts.GetHistoricalData();
            if (object.symbol != null) {
                if (typeof object.symbol !== "object")
                    throw TypeError(".fincharts.GetHistoricalData.symbol: object expected");
                message.symbol = $root.fincharts.Symbol.fromObject(object.symbol);
            }
            switch (object.step) {
            case "t1sec":
            case 0:
                message.step = 0;
                break;
            case "t5sec":
            case 1:
                message.step = 1;
                break;
            case "t15sec":
            case 2:
                message.step = 2;
                break;
            case "t30sec":
            case 3:
                message.step = 3;
                break;
            case "t1min":
            case 4:
                message.step = 4;
                break;
            case "t2min":
            case 5:
                message.step = 5;
                break;
            case "t3min":
            case 6:
                message.step = 6;
                break;
            case "t4min":
            case 7:
                message.step = 7;
                break;
            case "t5min":
            case 8:
                message.step = 8;
                break;
            case "t10min":
            case 9:
                message.step = 9;
                break;
            case "t15min":
            case 10:
                message.step = 10;
                break;
            case "t20min":
            case 11:
                message.step = 11;
                break;
            case "t25min":
            case 12:
                message.step = 12;
                break;
            case "t30min":
            case 13:
                message.step = 13;
                break;
            case "t45min":
            case 14:
                message.step = 14;
                break;
            case "t1h":
            case 15:
                message.step = 15;
                break;
            case "t90min":
            case 16:
                message.step = 16;
                break;
            case "t2h":
            case 17:
                message.step = 17;
                break;
            case "t3h":
            case 18:
                message.step = 18;
                break;
            case "t4h":
            case 19:
                message.step = 19;
                break;
            case "t5h":
            case 20:
                message.step = 20;
                break;
            case "t6h":
            case 21:
                message.step = 21;
                break;
            case "t7h":
            case 22:
                message.step = 22;
                break;
            case "t8h":
            case 23:
                message.step = 23;
                break;
            case "t9h":
            case 24:
                message.step = 24;
                break;
            case "t10h":
            case 25:
                message.step = 25;
                break;
            case "t11h":
            case 26:
                message.step = 26;
                break;
            case "t12h":
            case 27:
                message.step = 27;
                break;
            case "t13h":
            case 28:
                message.step = 28;
                break;
            case "t14h":
            case 29:
                message.step = 29;
                break;
            case "t15h":
            case 30:
                message.step = 30;
                break;
            case "t16h":
            case 31:
                message.step = 31;
                break;
            case "t17h":
            case 32:
                message.step = 32;
                break;
            case "t18h":
            case 33:
                message.step = 33;
                break;
            case "t19h":
            case 34:
                message.step = 34;
                break;
            case "t20h":
            case 35:
                message.step = 35;
                break;
            case "t21h":
            case 36:
                message.step = 36;
                break;
            case "t22h":
            case 37:
                message.step = 37;
                break;
            case "t23h":
            case 38:
                message.step = 38;
                break;
            case "t1day":
            case 39:
                message.step = 39;
                break;
            case "t30h":
            case 40:
                message.step = 40;
                break;
            case "t36h":
            case 41:
                message.step = 41;
                break;
            case "t42h":
            case 42:
                message.step = 42;
                break;
            case "t2day":
            case 43:
                message.step = 43;
                break;
            case "t60h":
            case 44:
                message.step = 44;
                break;
            case "t3day":
            case 45:
                message.step = 45;
                break;
            case "t4day":
            case 46:
                message.step = 46;
                break;
            case "t5day":
            case 47:
                message.step = 47;
                break;
            case "t6day":
            case 48:
                message.step = 48;
                break;
            case "t1week":
            case 49:
                message.step = 49;
                break;
            case "t7day":
            case 50:
                message.step = 50;
                break;
            case "t8day":
            case 51:
                message.step = 51;
                break;
            case "t9day":
            case 52:
                message.step = 52;
                break;
            case "t10day":
            case 53:
                message.step = 53;
                break;
            case "t11day":
            case 54:
                message.step = 54;
                break;
            case "t12day":
            case 55:
                message.step = 55;
                break;
            case "t13day":
            case 56:
                message.step = 56;
                break;
            case "t14day":
            case 57:
                message.step = 57;
                break;
            case "t15day":
            case 58:
                message.step = 58;
                break;
            case "t16day":
            case 59:
                message.step = 59;
                break;
            case "t17day":
            case 60:
                message.step = 60;
                break;
            case "t18day":
            case 61:
                message.step = 61;
                break;
            case "t19day":
            case 62:
                message.step = 62;
                break;
            case "t20day":
            case 63:
                message.step = 63;
                break;
            case "t21day":
            case 64:
                message.step = 64;
                break;
            case "t22day":
            case 65:
                message.step = 65;
                break;
            case "t23day":
            case 66:
                message.step = 66;
                break;
            case "t24day":
            case 67:
                message.step = 67;
                break;
            case "t25day":
            case 68:
                message.step = 68;
                break;
            case "t26day":
            case 69:
                message.step = 69;
                break;
            case "t27day":
            case 70:
                message.step = 70;
                break;
            case "t28day":
            case 71:
                message.step = 71;
                break;
            case "t29day":
            case 72:
                message.step = 72;
                break;
            case "t30day":
            case 73:
                message.step = 73;
                break;
            case "t1m":
            case 74:
                message.step = 74;
                break;
            case "t45day":
            case 75:
                message.step = 75;
                break;
            case "t60day":
            case 76:
                message.step = 76;
                break;
            case "t75day":
            case 77:
                message.step = 77;
                break;
            case "t3m":
            case 78:
                message.step = 78;
                break;
            case "t120day":
            case 79:
                message.step = 79;
                break;
            case "t150day":
            case 80:
                message.step = 80;
                break;
            case "t6m":
            case 81:
                message.step = 81;
                break;
            case "t210day":
            case 82:
                message.step = 82;
                break;
            case "t240day":
            case 83:
                message.step = 83;
                break;
            case "t270day":
            case 84:
                message.step = 84;
                break;
            case "t300day":
            case 85:
                message.step = 85;
                break;
            case "t330day":
            case 86:
                message.step = 86;
                break;
            case "t1y":
            case 87:
                message.step = 87;
                break;
            case "t18m":
            case 88:
                message.step = 88;
                break;
            case "t2y":
            case 89:
                message.step = 89;
                break;
            }
            if (object.begin != null) {
                if (typeof object.begin !== "object")
                    throw TypeError(".fincharts.GetHistoricalData.begin: object expected");
                message.begin = $root.google.protobuf.Timestamp.fromObject(object.begin);
            }
            if (object.end != null) {
                if (typeof object.end !== "object")
                    throw TypeError(".fincharts.GetHistoricalData.end: object expected");
                message.end = $root.google.protobuf.Timestamp.fromObject(object.end);
            }
            if (object.indicators) {
                if (!Array.isArray(object.indicators))
                    throw TypeError(".fincharts.GetHistoricalData.indicators: array expected");
                message.indicators = [];
                for (let i = 0; i < object.indicators.length; ++i) {
                    if (typeof object.indicators[i] !== "object")
                        throw TypeError(".fincharts.GetHistoricalData.indicators: object expected");
                    message.indicators[i] = $root.fincharts.IndicatorRequest.fromObject(object.indicators[i]);
                }
            }
            if (object.strategy != null) {
                if (typeof object.strategy !== "object")
                    throw TypeError(".fincharts.GetHistoricalData.strategy: object expected");
                message.strategy = $root.fincharts.StrategyRequest.fromObject(object.strategy);
            }
            return message;
        };

        /**
         * Creates a plain object from a GetHistoricalData message. Also converts values to other types if specified.
         * @function toObject
         * @memberof fincharts.GetHistoricalData
         * @static
         * @param {fincharts.GetHistoricalData} message GetHistoricalData
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetHistoricalData.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.indicators = [];
            if (options.defaults) {
                object.symbol = null;
                object.step = options.enums === String ? "t1sec" : 0;
                object.begin = null;
                object.end = null;
                object.strategy = null;
            }
            if (message.symbol != null && message.hasOwnProperty("symbol"))
                object.symbol = $root.fincharts.Symbol.toObject(message.symbol, options);
            if (message.step != null && message.hasOwnProperty("step"))
                object.step = options.enums === String ? $root.fincharts.TimeStep[message.step] : message.step;
            if (message.begin != null && message.hasOwnProperty("begin"))
                object.begin = $root.google.protobuf.Timestamp.toObject(message.begin, options);
            if (message.end != null && message.hasOwnProperty("end"))
                object.end = $root.google.protobuf.Timestamp.toObject(message.end, options);
            if (message.indicators && message.indicators.length) {
                object.indicators = [];
                for (let j = 0; j < message.indicators.length; ++j)
                    object.indicators[j] = $root.fincharts.IndicatorRequest.toObject(message.indicators[j], options);
            }
            if (message.strategy != null && message.hasOwnProperty("strategy"))
                object.strategy = $root.fincharts.StrategyRequest.toObject(message.strategy, options);
            return object;
        };

        /**
         * Converts this GetHistoricalData to JSON.
         * @function toJSON
         * @memberof fincharts.GetHistoricalData
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetHistoricalData.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return GetHistoricalData;
    })();

    fincharts.GetSupportedIndicators = (function() {

        /**
         * Properties of a GetSupportedIndicators.
         * @memberof fincharts
         * @interface IGetSupportedIndicators
         */

        /**
         * Constructs a new GetSupportedIndicators.
         * @memberof fincharts
         * @classdesc Represents a GetSupportedIndicators.
         * @implements IGetSupportedIndicators
         * @constructor
         * @param {fincharts.IGetSupportedIndicators=} [properties] Properties to set
         */
        function GetSupportedIndicators(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new GetSupportedIndicators instance using the specified properties.
         * @function create
         * @memberof fincharts.GetSupportedIndicators
         * @static
         * @param {fincharts.IGetSupportedIndicators=} [properties] Properties to set
         * @returns {fincharts.GetSupportedIndicators} GetSupportedIndicators instance
         */
        GetSupportedIndicators.create = function create(properties) {
            return new GetSupportedIndicators(properties);
        };

        /**
         * Encodes the specified GetSupportedIndicators message. Does not implicitly {@link fincharts.GetSupportedIndicators.verify|verify} messages.
         * @function encode
         * @memberof fincharts.GetSupportedIndicators
         * @static
         * @param {fincharts.IGetSupportedIndicators} message GetSupportedIndicators message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetSupportedIndicators.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified GetSupportedIndicators message, length delimited. Does not implicitly {@link fincharts.GetSupportedIndicators.verify|verify} messages.
         * @function encodeDelimited
         * @memberof fincharts.GetSupportedIndicators
         * @static
         * @param {fincharts.IGetSupportedIndicators} message GetSupportedIndicators message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetSupportedIndicators.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GetSupportedIndicators message from the specified reader or buffer.
         * @function decode
         * @memberof fincharts.GetSupportedIndicators
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {fincharts.GetSupportedIndicators} GetSupportedIndicators
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetSupportedIndicators.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.fincharts.GetSupportedIndicators();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GetSupportedIndicators message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof fincharts.GetSupportedIndicators
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {fincharts.GetSupportedIndicators} GetSupportedIndicators
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetSupportedIndicators.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GetSupportedIndicators message.
         * @function verify
         * @memberof fincharts.GetSupportedIndicators
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GetSupportedIndicators.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a GetSupportedIndicators message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof fincharts.GetSupportedIndicators
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {fincharts.GetSupportedIndicators} GetSupportedIndicators
         */
        GetSupportedIndicators.fromObject = function fromObject(object) {
            if (object instanceof $root.fincharts.GetSupportedIndicators)
                return object;
            return new $root.fincharts.GetSupportedIndicators();
        };

        /**
         * Creates a plain object from a GetSupportedIndicators message. Also converts values to other types if specified.
         * @function toObject
         * @memberof fincharts.GetSupportedIndicators
         * @static
         * @param {fincharts.GetSupportedIndicators} message GetSupportedIndicators
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetSupportedIndicators.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this GetSupportedIndicators to JSON.
         * @function toJSON
         * @memberof fincharts.GetSupportedIndicators
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetSupportedIndicators.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return GetSupportedIndicators;
    })();

    fincharts.GetSupportedStrategies = (function() {

        /**
         * Properties of a GetSupportedStrategies.
         * @memberof fincharts
         * @interface IGetSupportedStrategies
         */

        /**
         * Constructs a new GetSupportedStrategies.
         * @memberof fincharts
         * @classdesc Represents a GetSupportedStrategies.
         * @implements IGetSupportedStrategies
         * @constructor
         * @param {fincharts.IGetSupportedStrategies=} [properties] Properties to set
         */
        function GetSupportedStrategies(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new GetSupportedStrategies instance using the specified properties.
         * @function create
         * @memberof fincharts.GetSupportedStrategies
         * @static
         * @param {fincharts.IGetSupportedStrategies=} [properties] Properties to set
         * @returns {fincharts.GetSupportedStrategies} GetSupportedStrategies instance
         */
        GetSupportedStrategies.create = function create(properties) {
            return new GetSupportedStrategies(properties);
        };

        /**
         * Encodes the specified GetSupportedStrategies message. Does not implicitly {@link fincharts.GetSupportedStrategies.verify|verify} messages.
         * @function encode
         * @memberof fincharts.GetSupportedStrategies
         * @static
         * @param {fincharts.IGetSupportedStrategies} message GetSupportedStrategies message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetSupportedStrategies.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified GetSupportedStrategies message, length delimited. Does not implicitly {@link fincharts.GetSupportedStrategies.verify|verify} messages.
         * @function encodeDelimited
         * @memberof fincharts.GetSupportedStrategies
         * @static
         * @param {fincharts.IGetSupportedStrategies} message GetSupportedStrategies message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetSupportedStrategies.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GetSupportedStrategies message from the specified reader or buffer.
         * @function decode
         * @memberof fincharts.GetSupportedStrategies
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {fincharts.GetSupportedStrategies} GetSupportedStrategies
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetSupportedStrategies.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.fincharts.GetSupportedStrategies();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GetSupportedStrategies message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof fincharts.GetSupportedStrategies
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {fincharts.GetSupportedStrategies} GetSupportedStrategies
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetSupportedStrategies.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GetSupportedStrategies message.
         * @function verify
         * @memberof fincharts.GetSupportedStrategies
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GetSupportedStrategies.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a GetSupportedStrategies message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof fincharts.GetSupportedStrategies
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {fincharts.GetSupportedStrategies} GetSupportedStrategies
         */
        GetSupportedStrategies.fromObject = function fromObject(object) {
            if (object instanceof $root.fincharts.GetSupportedStrategies)
                return object;
            return new $root.fincharts.GetSupportedStrategies();
        };

        /**
         * Creates a plain object from a GetSupportedStrategies message. Also converts values to other types if specified.
         * @function toObject
         * @memberof fincharts.GetSupportedStrategies
         * @static
         * @param {fincharts.GetSupportedStrategies} message GetSupportedStrategies
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetSupportedStrategies.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this GetSupportedStrategies to JSON.
         * @function toJSON
         * @memberof fincharts.GetSupportedStrategies
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetSupportedStrategies.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return GetSupportedStrategies;
    })();

    fincharts.ClientRequest = (function() {

        /**
         * Properties of a ClientRequest.
         * @memberof fincharts
         * @interface IClientRequest
         * @property {number|Long|null} [ID] ClientRequest ID
         * @property {fincharts.IAuthentificateRequest|null} [authRequest] ClientRequest authRequest
         * @property {fincharts.ISubscribe|null} [subscribe] ClientRequest subscribe
         * @property {fincharts.IGetHistoricalData|null} [getHistoricalData] ClientRequest getHistoricalData
         * @property {fincharts.ICancel|null} [cancel] ClientRequest cancel
         * @property {fincharts.IGetSupportedIndicators|null} [getSupportedIndicators] ClientRequest getSupportedIndicators
         * @property {fincharts.IGetSupportedStrategies|null} [getSupportedStrategies] ClientRequest getSupportedStrategies
         */

        /**
         * Constructs a new ClientRequest.
         * @memberof fincharts
         * @classdesc Represents a ClientRequest.
         * @implements IClientRequest
         * @constructor
         * @param {fincharts.IClientRequest=} [properties] Properties to set
         */
        function ClientRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ClientRequest ID.
         * @member {number|Long} ID
         * @memberof fincharts.ClientRequest
         * @instance
         */
        ClientRequest.prototype.ID = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * ClientRequest authRequest.
         * @member {fincharts.IAuthentificateRequest|null|undefined} authRequest
         * @memberof fincharts.ClientRequest
         * @instance
         */
        ClientRequest.prototype.authRequest = null;

        /**
         * ClientRequest subscribe.
         * @member {fincharts.ISubscribe|null|undefined} subscribe
         * @memberof fincharts.ClientRequest
         * @instance
         */
        ClientRequest.prototype.subscribe = null;

        /**
         * ClientRequest getHistoricalData.
         * @member {fincharts.IGetHistoricalData|null|undefined} getHistoricalData
         * @memberof fincharts.ClientRequest
         * @instance
         */
        ClientRequest.prototype.getHistoricalData = null;

        /**
         * ClientRequest cancel.
         * @member {fincharts.ICancel|null|undefined} cancel
         * @memberof fincharts.ClientRequest
         * @instance
         */
        ClientRequest.prototype.cancel = null;

        /**
         * ClientRequest getSupportedIndicators.
         * @member {fincharts.IGetSupportedIndicators|null|undefined} getSupportedIndicators
         * @memberof fincharts.ClientRequest
         * @instance
         */
        ClientRequest.prototype.getSupportedIndicators = null;

        /**
         * ClientRequest getSupportedStrategies.
         * @member {fincharts.IGetSupportedStrategies|null|undefined} getSupportedStrategies
         * @memberof fincharts.ClientRequest
         * @instance
         */
        ClientRequest.prototype.getSupportedStrategies = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * ClientRequest request.
         * @member {"authRequest"|"subscribe"|"getHistoricalData"|"cancel"|"getSupportedIndicators"|"getSupportedStrategies"|undefined} request
         * @memberof fincharts.ClientRequest
         * @instance
         */
        Object.defineProperty(ClientRequest.prototype, "request", {
            get: $util.oneOfGetter($oneOfFields = ["authRequest", "subscribe", "getHistoricalData", "cancel", "getSupportedIndicators", "getSupportedStrategies"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new ClientRequest instance using the specified properties.
         * @function create
         * @memberof fincharts.ClientRequest
         * @static
         * @param {fincharts.IClientRequest=} [properties] Properties to set
         * @returns {fincharts.ClientRequest} ClientRequest instance
         */
        ClientRequest.create = function create(properties) {
            return new ClientRequest(properties);
        };

        /**
         * Encodes the specified ClientRequest message. Does not implicitly {@link fincharts.ClientRequest.verify|verify} messages.
         * @function encode
         * @memberof fincharts.ClientRequest
         * @static
         * @param {fincharts.IClientRequest} message ClientRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ClientRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.ID != null && Object.hasOwnProperty.call(message, "ID"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.ID);
            if (message.authRequest != null && Object.hasOwnProperty.call(message, "authRequest"))
                $root.fincharts.AuthentificateRequest.encode(message.authRequest, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.subscribe != null && Object.hasOwnProperty.call(message, "subscribe"))
                $root.fincharts.Subscribe.encode(message.subscribe, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.getHistoricalData != null && Object.hasOwnProperty.call(message, "getHistoricalData"))
                $root.fincharts.GetHistoricalData.encode(message.getHistoricalData, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.cancel != null && Object.hasOwnProperty.call(message, "cancel"))
                $root.fincharts.Cancel.encode(message.cancel, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            if (message.getSupportedIndicators != null && Object.hasOwnProperty.call(message, "getSupportedIndicators"))
                $root.fincharts.GetSupportedIndicators.encode(message.getSupportedIndicators, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            if (message.getSupportedStrategies != null && Object.hasOwnProperty.call(message, "getSupportedStrategies"))
                $root.fincharts.GetSupportedStrategies.encode(message.getSupportedStrategies, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified ClientRequest message, length delimited. Does not implicitly {@link fincharts.ClientRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof fincharts.ClientRequest
         * @static
         * @param {fincharts.IClientRequest} message ClientRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ClientRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ClientRequest message from the specified reader or buffer.
         * @function decode
         * @memberof fincharts.ClientRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {fincharts.ClientRequest} ClientRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ClientRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.fincharts.ClientRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.ID = reader.uint64();
                    break;
                case 2:
                    message.authRequest = $root.fincharts.AuthentificateRequest.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.subscribe = $root.fincharts.Subscribe.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.getHistoricalData = $root.fincharts.GetHistoricalData.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.cancel = $root.fincharts.Cancel.decode(reader, reader.uint32());
                    break;
                case 6:
                    message.getSupportedIndicators = $root.fincharts.GetSupportedIndicators.decode(reader, reader.uint32());
                    break;
                case 7:
                    message.getSupportedStrategies = $root.fincharts.GetSupportedStrategies.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ClientRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof fincharts.ClientRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {fincharts.ClientRequest} ClientRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ClientRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ClientRequest message.
         * @function verify
         * @memberof fincharts.ClientRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ClientRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.ID != null && message.hasOwnProperty("ID"))
                if (!$util.isInteger(message.ID) && !(message.ID && $util.isInteger(message.ID.low) && $util.isInteger(message.ID.high)))
                    return "ID: integer|Long expected";
            if (message.authRequest != null && message.hasOwnProperty("authRequest")) {
                properties.request = 1;
                {
                    let error = $root.fincharts.AuthentificateRequest.verify(message.authRequest);
                    if (error)
                        return "authRequest." + error;
                }
            }
            if (message.subscribe != null && message.hasOwnProperty("subscribe")) {
                if (properties.request === 1)
                    return "request: multiple values";
                properties.request = 1;
                {
                    let error = $root.fincharts.Subscribe.verify(message.subscribe);
                    if (error)
                        return "subscribe." + error;
                }
            }
            if (message.getHistoricalData != null && message.hasOwnProperty("getHistoricalData")) {
                if (properties.request === 1)
                    return "request: multiple values";
                properties.request = 1;
                {
                    let error = $root.fincharts.GetHistoricalData.verify(message.getHistoricalData);
                    if (error)
                        return "getHistoricalData." + error;
                }
            }
            if (message.cancel != null && message.hasOwnProperty("cancel")) {
                if (properties.request === 1)
                    return "request: multiple values";
                properties.request = 1;
                {
                    let error = $root.fincharts.Cancel.verify(message.cancel);
                    if (error)
                        return "cancel." + error;
                }
            }
            if (message.getSupportedIndicators != null && message.hasOwnProperty("getSupportedIndicators")) {
                if (properties.request === 1)
                    return "request: multiple values";
                properties.request = 1;
                {
                    let error = $root.fincharts.GetSupportedIndicators.verify(message.getSupportedIndicators);
                    if (error)
                        return "getSupportedIndicators." + error;
                }
            }
            if (message.getSupportedStrategies != null && message.hasOwnProperty("getSupportedStrategies")) {
                if (properties.request === 1)
                    return "request: multiple values";
                properties.request = 1;
                {
                    let error = $root.fincharts.GetSupportedStrategies.verify(message.getSupportedStrategies);
                    if (error)
                        return "getSupportedStrategies." + error;
                }
            }
            return null;
        };

        /**
         * Creates a ClientRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof fincharts.ClientRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {fincharts.ClientRequest} ClientRequest
         */
        ClientRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.fincharts.ClientRequest)
                return object;
            let message = new $root.fincharts.ClientRequest();
            if (object.ID != null)
                if ($util.Long)
                    (message.ID = $util.Long.fromValue(object.ID)).unsigned = true;
                else if (typeof object.ID === "string")
                    message.ID = parseInt(object.ID, 10);
                else if (typeof object.ID === "number")
                    message.ID = object.ID;
                else if (typeof object.ID === "object")
                    message.ID = new $util.LongBits(object.ID.low >>> 0, object.ID.high >>> 0).toNumber(true);
            if (object.authRequest != null) {
                if (typeof object.authRequest !== "object")
                    throw TypeError(".fincharts.ClientRequest.authRequest: object expected");
                message.authRequest = $root.fincharts.AuthentificateRequest.fromObject(object.authRequest);
            }
            if (object.subscribe != null) {
                if (typeof object.subscribe !== "object")
                    throw TypeError(".fincharts.ClientRequest.subscribe: object expected");
                message.subscribe = $root.fincharts.Subscribe.fromObject(object.subscribe);
            }
            if (object.getHistoricalData != null) {
                if (typeof object.getHistoricalData !== "object")
                    throw TypeError(".fincharts.ClientRequest.getHistoricalData: object expected");
                message.getHistoricalData = $root.fincharts.GetHistoricalData.fromObject(object.getHistoricalData);
            }
            if (object.cancel != null) {
                if (typeof object.cancel !== "object")
                    throw TypeError(".fincharts.ClientRequest.cancel: object expected");
                message.cancel = $root.fincharts.Cancel.fromObject(object.cancel);
            }
            if (object.getSupportedIndicators != null) {
                if (typeof object.getSupportedIndicators !== "object")
                    throw TypeError(".fincharts.ClientRequest.getSupportedIndicators: object expected");
                message.getSupportedIndicators = $root.fincharts.GetSupportedIndicators.fromObject(object.getSupportedIndicators);
            }
            if (object.getSupportedStrategies != null) {
                if (typeof object.getSupportedStrategies !== "object")
                    throw TypeError(".fincharts.ClientRequest.getSupportedStrategies: object expected");
                message.getSupportedStrategies = $root.fincharts.GetSupportedStrategies.fromObject(object.getSupportedStrategies);
            }
            return message;
        };

        /**
         * Creates a plain object from a ClientRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof fincharts.ClientRequest
         * @static
         * @param {fincharts.ClientRequest} message ClientRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ClientRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.ID = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.ID = options.longs === String ? "0" : 0;
            if (message.ID != null && message.hasOwnProperty("ID"))
                if (typeof message.ID === "number")
                    object.ID = options.longs === String ? String(message.ID) : message.ID;
                else
                    object.ID = options.longs === String ? $util.Long.prototype.toString.call(message.ID) : options.longs === Number ? new $util.LongBits(message.ID.low >>> 0, message.ID.high >>> 0).toNumber(true) : message.ID;
            if (message.authRequest != null && message.hasOwnProperty("authRequest")) {
                object.authRequest = $root.fincharts.AuthentificateRequest.toObject(message.authRequest, options);
                if (options.oneofs)
                    object.request = "authRequest";
            }
            if (message.subscribe != null && message.hasOwnProperty("subscribe")) {
                object.subscribe = $root.fincharts.Subscribe.toObject(message.subscribe, options);
                if (options.oneofs)
                    object.request = "subscribe";
            }
            if (message.getHistoricalData != null && message.hasOwnProperty("getHistoricalData")) {
                object.getHistoricalData = $root.fincharts.GetHistoricalData.toObject(message.getHistoricalData, options);
                if (options.oneofs)
                    object.request = "getHistoricalData";
            }
            if (message.cancel != null && message.hasOwnProperty("cancel")) {
                object.cancel = $root.fincharts.Cancel.toObject(message.cancel, options);
                if (options.oneofs)
                    object.request = "cancel";
            }
            if (message.getSupportedIndicators != null && message.hasOwnProperty("getSupportedIndicators")) {
                object.getSupportedIndicators = $root.fincharts.GetSupportedIndicators.toObject(message.getSupportedIndicators, options);
                if (options.oneofs)
                    object.request = "getSupportedIndicators";
            }
            if (message.getSupportedStrategies != null && message.hasOwnProperty("getSupportedStrategies")) {
                object.getSupportedStrategies = $root.fincharts.GetSupportedStrategies.toObject(message.getSupportedStrategies, options);
                if (options.oneofs)
                    object.request = "getSupportedStrategies";
            }
            return object;
        };

        /**
         * Converts this ClientRequest to JSON.
         * @function toJSON
         * @memberof fincharts.ClientRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ClientRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ClientRequest;
    })();

    fincharts.Error = (function() {

        /**
         * Properties of an Error.
         * @memberof fincharts
         * @interface IError
         * @property {string|null} [error] Error error
         */

        /**
         * Constructs a new Error.
         * @memberof fincharts
         * @classdesc Represents an Error.
         * @implements IError
         * @constructor
         * @param {fincharts.IError=} [properties] Properties to set
         */
        function Error(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Error error.
         * @member {string} error
         * @memberof fincharts.Error
         * @instance
         */
        Error.prototype.error = "";

        /**
         * Creates a new Error instance using the specified properties.
         * @function create
         * @memberof fincharts.Error
         * @static
         * @param {fincharts.IError=} [properties] Properties to set
         * @returns {fincharts.Error} Error instance
         */
        Error.create = function create(properties) {
            return new Error(properties);
        };

        /**
         * Encodes the specified Error message. Does not implicitly {@link fincharts.Error.verify|verify} messages.
         * @function encode
         * @memberof fincharts.Error
         * @static
         * @param {fincharts.IError} message Error message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Error.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.error != null && Object.hasOwnProperty.call(message, "error"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.error);
            return writer;
        };

        /**
         * Encodes the specified Error message, length delimited. Does not implicitly {@link fincharts.Error.verify|verify} messages.
         * @function encodeDelimited
         * @memberof fincharts.Error
         * @static
         * @param {fincharts.IError} message Error message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Error.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an Error message from the specified reader or buffer.
         * @function decode
         * @memberof fincharts.Error
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {fincharts.Error} Error
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Error.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.fincharts.Error();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.error = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an Error message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof fincharts.Error
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {fincharts.Error} Error
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Error.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an Error message.
         * @function verify
         * @memberof fincharts.Error
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Error.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.error != null && message.hasOwnProperty("error"))
                if (!$util.isString(message.error))
                    return "error: string expected";
            return null;
        };

        /**
         * Creates an Error message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof fincharts.Error
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {fincharts.Error} Error
         */
        Error.fromObject = function fromObject(object) {
            if (object instanceof $root.fincharts.Error)
                return object;
            let message = new $root.fincharts.Error();
            if (object.error != null)
                message.error = String(object.error);
            return message;
        };

        /**
         * Creates a plain object from an Error message. Also converts values to other types if specified.
         * @function toObject
         * @memberof fincharts.Error
         * @static
         * @param {fincharts.Error} message Error
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Error.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.error = "";
            if (message.error != null && message.hasOwnProperty("error"))
                object.error = message.error;
            return object;
        };

        /**
         * Converts this Error to JSON.
         * @function toJSON
         * @memberof fincharts.Error
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Error.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return Error;
    })();

    fincharts.IndicatorInfo = (function() {

        /**
         * Properties of an IndicatorInfo.
         * @memberof fincharts
         * @interface IIndicatorInfo
         * @property {string|null} [name] IndicatorInfo name
         * @property {string|null} [description] IndicatorInfo description
         */

        /**
         * Constructs a new IndicatorInfo.
         * @memberof fincharts
         * @classdesc Represents an IndicatorInfo.
         * @implements IIndicatorInfo
         * @constructor
         * @param {fincharts.IIndicatorInfo=} [properties] Properties to set
         */
        function IndicatorInfo(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * IndicatorInfo name.
         * @member {string} name
         * @memberof fincharts.IndicatorInfo
         * @instance
         */
        IndicatorInfo.prototype.name = "";

        /**
         * IndicatorInfo description.
         * @member {string} description
         * @memberof fincharts.IndicatorInfo
         * @instance
         */
        IndicatorInfo.prototype.description = "";

        /**
         * Creates a new IndicatorInfo instance using the specified properties.
         * @function create
         * @memberof fincharts.IndicatorInfo
         * @static
         * @param {fincharts.IIndicatorInfo=} [properties] Properties to set
         * @returns {fincharts.IndicatorInfo} IndicatorInfo instance
         */
        IndicatorInfo.create = function create(properties) {
            return new IndicatorInfo(properties);
        };

        /**
         * Encodes the specified IndicatorInfo message. Does not implicitly {@link fincharts.IndicatorInfo.verify|verify} messages.
         * @function encode
         * @memberof fincharts.IndicatorInfo
         * @static
         * @param {fincharts.IIndicatorInfo} message IndicatorInfo message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        IndicatorInfo.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
            if (message.description != null && Object.hasOwnProperty.call(message, "description"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.description);
            return writer;
        };

        /**
         * Encodes the specified IndicatorInfo message, length delimited. Does not implicitly {@link fincharts.IndicatorInfo.verify|verify} messages.
         * @function encodeDelimited
         * @memberof fincharts.IndicatorInfo
         * @static
         * @param {fincharts.IIndicatorInfo} message IndicatorInfo message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        IndicatorInfo.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an IndicatorInfo message from the specified reader or buffer.
         * @function decode
         * @memberof fincharts.IndicatorInfo
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {fincharts.IndicatorInfo} IndicatorInfo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        IndicatorInfo.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.fincharts.IndicatorInfo();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.name = reader.string();
                    break;
                case 2:
                    message.description = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an IndicatorInfo message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof fincharts.IndicatorInfo
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {fincharts.IndicatorInfo} IndicatorInfo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        IndicatorInfo.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an IndicatorInfo message.
         * @function verify
         * @memberof fincharts.IndicatorInfo
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        IndicatorInfo.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.name != null && message.hasOwnProperty("name"))
                if (!$util.isString(message.name))
                    return "name: string expected";
            if (message.description != null && message.hasOwnProperty("description"))
                if (!$util.isString(message.description))
                    return "description: string expected";
            return null;
        };

        /**
         * Creates an IndicatorInfo message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof fincharts.IndicatorInfo
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {fincharts.IndicatorInfo} IndicatorInfo
         */
        IndicatorInfo.fromObject = function fromObject(object) {
            if (object instanceof $root.fincharts.IndicatorInfo)
                return object;
            let message = new $root.fincharts.IndicatorInfo();
            if (object.name != null)
                message.name = String(object.name);
            if (object.description != null)
                message.description = String(object.description);
            return message;
        };

        /**
         * Creates a plain object from an IndicatorInfo message. Also converts values to other types if specified.
         * @function toObject
         * @memberof fincharts.IndicatorInfo
         * @static
         * @param {fincharts.IndicatorInfo} message IndicatorInfo
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        IndicatorInfo.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.name = "";
                object.description = "";
            }
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            if (message.description != null && message.hasOwnProperty("description"))
                object.description = message.description;
            return object;
        };

        /**
         * Converts this IndicatorInfo to JSON.
         * @function toJSON
         * @memberof fincharts.IndicatorInfo
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        IndicatorInfo.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return IndicatorInfo;
    })();

    fincharts.StrategyInfo = (function() {

        /**
         * Properties of a StrategyInfo.
         * @memberof fincharts
         * @interface IStrategyInfo
         * @property {string|null} [name] StrategyInfo name
         * @property {string|null} [description] StrategyInfo description
         */

        /**
         * Constructs a new StrategyInfo.
         * @memberof fincharts
         * @classdesc Represents a StrategyInfo.
         * @implements IStrategyInfo
         * @constructor
         * @param {fincharts.IStrategyInfo=} [properties] Properties to set
         */
        function StrategyInfo(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * StrategyInfo name.
         * @member {string} name
         * @memberof fincharts.StrategyInfo
         * @instance
         */
        StrategyInfo.prototype.name = "";

        /**
         * StrategyInfo description.
         * @member {string} description
         * @memberof fincharts.StrategyInfo
         * @instance
         */
        StrategyInfo.prototype.description = "";

        /**
         * Creates a new StrategyInfo instance using the specified properties.
         * @function create
         * @memberof fincharts.StrategyInfo
         * @static
         * @param {fincharts.IStrategyInfo=} [properties] Properties to set
         * @returns {fincharts.StrategyInfo} StrategyInfo instance
         */
        StrategyInfo.create = function create(properties) {
            return new StrategyInfo(properties);
        };

        /**
         * Encodes the specified StrategyInfo message. Does not implicitly {@link fincharts.StrategyInfo.verify|verify} messages.
         * @function encode
         * @memberof fincharts.StrategyInfo
         * @static
         * @param {fincharts.IStrategyInfo} message StrategyInfo message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StrategyInfo.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
            if (message.description != null && Object.hasOwnProperty.call(message, "description"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.description);
            return writer;
        };

        /**
         * Encodes the specified StrategyInfo message, length delimited. Does not implicitly {@link fincharts.StrategyInfo.verify|verify} messages.
         * @function encodeDelimited
         * @memberof fincharts.StrategyInfo
         * @static
         * @param {fincharts.IStrategyInfo} message StrategyInfo message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StrategyInfo.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a StrategyInfo message from the specified reader or buffer.
         * @function decode
         * @memberof fincharts.StrategyInfo
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {fincharts.StrategyInfo} StrategyInfo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StrategyInfo.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.fincharts.StrategyInfo();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.name = reader.string();
                    break;
                case 2:
                    message.description = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a StrategyInfo message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof fincharts.StrategyInfo
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {fincharts.StrategyInfo} StrategyInfo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StrategyInfo.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a StrategyInfo message.
         * @function verify
         * @memberof fincharts.StrategyInfo
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        StrategyInfo.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.name != null && message.hasOwnProperty("name"))
                if (!$util.isString(message.name))
                    return "name: string expected";
            if (message.description != null && message.hasOwnProperty("description"))
                if (!$util.isString(message.description))
                    return "description: string expected";
            return null;
        };

        /**
         * Creates a StrategyInfo message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof fincharts.StrategyInfo
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {fincharts.StrategyInfo} StrategyInfo
         */
        StrategyInfo.fromObject = function fromObject(object) {
            if (object instanceof $root.fincharts.StrategyInfo)
                return object;
            let message = new $root.fincharts.StrategyInfo();
            if (object.name != null)
                message.name = String(object.name);
            if (object.description != null)
                message.description = String(object.description);
            return message;
        };

        /**
         * Creates a plain object from a StrategyInfo message. Also converts values to other types if specified.
         * @function toObject
         * @memberof fincharts.StrategyInfo
         * @static
         * @param {fincharts.StrategyInfo} message StrategyInfo
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        StrategyInfo.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.name = "";
                object.description = "";
            }
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            if (message.description != null && message.hasOwnProperty("description"))
                object.description = message.description;
            return object;
        };

        /**
         * Converts this StrategyInfo to JSON.
         * @function toJSON
         * @memberof fincharts.StrategyInfo
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        StrategyInfo.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return StrategyInfo;
    })();

    fincharts.SupportedIndicators = (function() {

        /**
         * Properties of a SupportedIndicators.
         * @memberof fincharts
         * @interface ISupportedIndicators
         * @property {Array.<fincharts.IIndicatorInfo>|null} [indicators] SupportedIndicators indicators
         */

        /**
         * Constructs a new SupportedIndicators.
         * @memberof fincharts
         * @classdesc Represents a SupportedIndicators.
         * @implements ISupportedIndicators
         * @constructor
         * @param {fincharts.ISupportedIndicators=} [properties] Properties to set
         */
        function SupportedIndicators(properties) {
            this.indicators = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SupportedIndicators indicators.
         * @member {Array.<fincharts.IIndicatorInfo>} indicators
         * @memberof fincharts.SupportedIndicators
         * @instance
         */
        SupportedIndicators.prototype.indicators = $util.emptyArray;

        /**
         * Creates a new SupportedIndicators instance using the specified properties.
         * @function create
         * @memberof fincharts.SupportedIndicators
         * @static
         * @param {fincharts.ISupportedIndicators=} [properties] Properties to set
         * @returns {fincharts.SupportedIndicators} SupportedIndicators instance
         */
        SupportedIndicators.create = function create(properties) {
            return new SupportedIndicators(properties);
        };

        /**
         * Encodes the specified SupportedIndicators message. Does not implicitly {@link fincharts.SupportedIndicators.verify|verify} messages.
         * @function encode
         * @memberof fincharts.SupportedIndicators
         * @static
         * @param {fincharts.ISupportedIndicators} message SupportedIndicators message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SupportedIndicators.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.indicators != null && message.indicators.length)
                for (let i = 0; i < message.indicators.length; ++i)
                    $root.fincharts.IndicatorInfo.encode(message.indicators[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified SupportedIndicators message, length delimited. Does not implicitly {@link fincharts.SupportedIndicators.verify|verify} messages.
         * @function encodeDelimited
         * @memberof fincharts.SupportedIndicators
         * @static
         * @param {fincharts.ISupportedIndicators} message SupportedIndicators message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SupportedIndicators.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SupportedIndicators message from the specified reader or buffer.
         * @function decode
         * @memberof fincharts.SupportedIndicators
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {fincharts.SupportedIndicators} SupportedIndicators
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SupportedIndicators.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.fincharts.SupportedIndicators();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.indicators && message.indicators.length))
                        message.indicators = [];
                    message.indicators.push($root.fincharts.IndicatorInfo.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SupportedIndicators message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof fincharts.SupportedIndicators
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {fincharts.SupportedIndicators} SupportedIndicators
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SupportedIndicators.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SupportedIndicators message.
         * @function verify
         * @memberof fincharts.SupportedIndicators
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SupportedIndicators.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.indicators != null && message.hasOwnProperty("indicators")) {
                if (!Array.isArray(message.indicators))
                    return "indicators: array expected";
                for (let i = 0; i < message.indicators.length; ++i) {
                    let error = $root.fincharts.IndicatorInfo.verify(message.indicators[i]);
                    if (error)
                        return "indicators." + error;
                }
            }
            return null;
        };

        /**
         * Creates a SupportedIndicators message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof fincharts.SupportedIndicators
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {fincharts.SupportedIndicators} SupportedIndicators
         */
        SupportedIndicators.fromObject = function fromObject(object) {
            if (object instanceof $root.fincharts.SupportedIndicators)
                return object;
            let message = new $root.fincharts.SupportedIndicators();
            if (object.indicators) {
                if (!Array.isArray(object.indicators))
                    throw TypeError(".fincharts.SupportedIndicators.indicators: array expected");
                message.indicators = [];
                for (let i = 0; i < object.indicators.length; ++i) {
                    if (typeof object.indicators[i] !== "object")
                        throw TypeError(".fincharts.SupportedIndicators.indicators: object expected");
                    message.indicators[i] = $root.fincharts.IndicatorInfo.fromObject(object.indicators[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a SupportedIndicators message. Also converts values to other types if specified.
         * @function toObject
         * @memberof fincharts.SupportedIndicators
         * @static
         * @param {fincharts.SupportedIndicators} message SupportedIndicators
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SupportedIndicators.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.indicators = [];
            if (message.indicators && message.indicators.length) {
                object.indicators = [];
                for (let j = 0; j < message.indicators.length; ++j)
                    object.indicators[j] = $root.fincharts.IndicatorInfo.toObject(message.indicators[j], options);
            }
            return object;
        };

        /**
         * Converts this SupportedIndicators to JSON.
         * @function toJSON
         * @memberof fincharts.SupportedIndicators
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SupportedIndicators.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return SupportedIndicators;
    })();

    fincharts.SupportedStrategies = (function() {

        /**
         * Properties of a SupportedStrategies.
         * @memberof fincharts
         * @interface ISupportedStrategies
         * @property {Array.<fincharts.IStrategyInfo>|null} [strategies] SupportedStrategies strategies
         */

        /**
         * Constructs a new SupportedStrategies.
         * @memberof fincharts
         * @classdesc Represents a SupportedStrategies.
         * @implements ISupportedStrategies
         * @constructor
         * @param {fincharts.ISupportedStrategies=} [properties] Properties to set
         */
        function SupportedStrategies(properties) {
            this.strategies = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SupportedStrategies strategies.
         * @member {Array.<fincharts.IStrategyInfo>} strategies
         * @memberof fincharts.SupportedStrategies
         * @instance
         */
        SupportedStrategies.prototype.strategies = $util.emptyArray;

        /**
         * Creates a new SupportedStrategies instance using the specified properties.
         * @function create
         * @memberof fincharts.SupportedStrategies
         * @static
         * @param {fincharts.ISupportedStrategies=} [properties] Properties to set
         * @returns {fincharts.SupportedStrategies} SupportedStrategies instance
         */
        SupportedStrategies.create = function create(properties) {
            return new SupportedStrategies(properties);
        };

        /**
         * Encodes the specified SupportedStrategies message. Does not implicitly {@link fincharts.SupportedStrategies.verify|verify} messages.
         * @function encode
         * @memberof fincharts.SupportedStrategies
         * @static
         * @param {fincharts.ISupportedStrategies} message SupportedStrategies message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SupportedStrategies.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.strategies != null && message.strategies.length)
                for (let i = 0; i < message.strategies.length; ++i)
                    $root.fincharts.StrategyInfo.encode(message.strategies[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified SupportedStrategies message, length delimited. Does not implicitly {@link fincharts.SupportedStrategies.verify|verify} messages.
         * @function encodeDelimited
         * @memberof fincharts.SupportedStrategies
         * @static
         * @param {fincharts.ISupportedStrategies} message SupportedStrategies message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SupportedStrategies.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SupportedStrategies message from the specified reader or buffer.
         * @function decode
         * @memberof fincharts.SupportedStrategies
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {fincharts.SupportedStrategies} SupportedStrategies
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SupportedStrategies.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.fincharts.SupportedStrategies();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.strategies && message.strategies.length))
                        message.strategies = [];
                    message.strategies.push($root.fincharts.StrategyInfo.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SupportedStrategies message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof fincharts.SupportedStrategies
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {fincharts.SupportedStrategies} SupportedStrategies
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SupportedStrategies.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SupportedStrategies message.
         * @function verify
         * @memberof fincharts.SupportedStrategies
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SupportedStrategies.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.strategies != null && message.hasOwnProperty("strategies")) {
                if (!Array.isArray(message.strategies))
                    return "strategies: array expected";
                for (let i = 0; i < message.strategies.length; ++i) {
                    let error = $root.fincharts.StrategyInfo.verify(message.strategies[i]);
                    if (error)
                        return "strategies." + error;
                }
            }
            return null;
        };

        /**
         * Creates a SupportedStrategies message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof fincharts.SupportedStrategies
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {fincharts.SupportedStrategies} SupportedStrategies
         */
        SupportedStrategies.fromObject = function fromObject(object) {
            if (object instanceof $root.fincharts.SupportedStrategies)
                return object;
            let message = new $root.fincharts.SupportedStrategies();
            if (object.strategies) {
                if (!Array.isArray(object.strategies))
                    throw TypeError(".fincharts.SupportedStrategies.strategies: array expected");
                message.strategies = [];
                for (let i = 0; i < object.strategies.length; ++i) {
                    if (typeof object.strategies[i] !== "object")
                        throw TypeError(".fincharts.SupportedStrategies.strategies: object expected");
                    message.strategies[i] = $root.fincharts.StrategyInfo.fromObject(object.strategies[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a SupportedStrategies message. Also converts values to other types if specified.
         * @function toObject
         * @memberof fincharts.SupportedStrategies
         * @static
         * @param {fincharts.SupportedStrategies} message SupportedStrategies
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SupportedStrategies.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.strategies = [];
            if (message.strategies && message.strategies.length) {
                object.strategies = [];
                for (let j = 0; j < message.strategies.length; ++j)
                    object.strategies[j] = $root.fincharts.StrategyInfo.toObject(message.strategies[j], options);
            }
            return object;
        };

        /**
         * Converts this SupportedStrategies to JSON.
         * @function toJSON
         * @memberof fincharts.SupportedStrategies
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SupportedStrategies.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return SupportedStrategies;
    })();

    fincharts.ServerResponse = (function() {

        /**
         * Properties of a ServerResponse.
         * @memberof fincharts
         * @interface IServerResponse
         * @property {number|Long|null} [replyToID] ServerResponse replyToID
         * @property {fincharts.ServerResponse.Code|null} [code] ServerResponse code
         * @property {fincharts.IData|null} [data] ServerResponse data
         * @property {fincharts.IError|null} [error] ServerResponse error
         * @property {google.protobuf.IEmpty|null} [empty] ServerResponse empty
         * @property {fincharts.ISupportedIndicators|null} [supportedIndicators] ServerResponse supportedIndicators
         * @property {fincharts.ISupportedStrategies|null} [supportedStrategies] ServerResponse supportedStrategies
         */

        /**
         * Constructs a new ServerResponse.
         * @memberof fincharts
         * @classdesc Represents a ServerResponse.
         * @implements IServerResponse
         * @constructor
         * @param {fincharts.IServerResponse=} [properties] Properties to set
         */
        function ServerResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ServerResponse replyToID.
         * @member {number|Long} replyToID
         * @memberof fincharts.ServerResponse
         * @instance
         */
        ServerResponse.prototype.replyToID = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * ServerResponse code.
         * @member {fincharts.ServerResponse.Code} code
         * @memberof fincharts.ServerResponse
         * @instance
         */
        ServerResponse.prototype.code = 0;

        /**
         * ServerResponse data.
         * @member {fincharts.IData|null|undefined} data
         * @memberof fincharts.ServerResponse
         * @instance
         */
        ServerResponse.prototype.data = null;

        /**
         * ServerResponse error.
         * @member {fincharts.IError|null|undefined} error
         * @memberof fincharts.ServerResponse
         * @instance
         */
        ServerResponse.prototype.error = null;

        /**
         * ServerResponse empty.
         * @member {google.protobuf.IEmpty|null|undefined} empty
         * @memberof fincharts.ServerResponse
         * @instance
         */
        ServerResponse.prototype.empty = null;

        /**
         * ServerResponse supportedIndicators.
         * @member {fincharts.ISupportedIndicators|null|undefined} supportedIndicators
         * @memberof fincharts.ServerResponse
         * @instance
         */
        ServerResponse.prototype.supportedIndicators = null;

        /**
         * ServerResponse supportedStrategies.
         * @member {fincharts.ISupportedStrategies|null|undefined} supportedStrategies
         * @memberof fincharts.ServerResponse
         * @instance
         */
        ServerResponse.prototype.supportedStrategies = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * ServerResponse response.
         * @member {"data"|"error"|"empty"|"supportedIndicators"|"supportedStrategies"|undefined} response
         * @memberof fincharts.ServerResponse
         * @instance
         */
        Object.defineProperty(ServerResponse.prototype, "response", {
            get: $util.oneOfGetter($oneOfFields = ["data", "error", "empty", "supportedIndicators", "supportedStrategies"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new ServerResponse instance using the specified properties.
         * @function create
         * @memberof fincharts.ServerResponse
         * @static
         * @param {fincharts.IServerResponse=} [properties] Properties to set
         * @returns {fincharts.ServerResponse} ServerResponse instance
         */
        ServerResponse.create = function create(properties) {
            return new ServerResponse(properties);
        };

        /**
         * Encodes the specified ServerResponse message. Does not implicitly {@link fincharts.ServerResponse.verify|verify} messages.
         * @function encode
         * @memberof fincharts.ServerResponse
         * @static
         * @param {fincharts.IServerResponse} message ServerResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ServerResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.replyToID != null && Object.hasOwnProperty.call(message, "replyToID"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.replyToID);
            if (message.code != null && Object.hasOwnProperty.call(message, "code"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.code);
            if (message.data != null && Object.hasOwnProperty.call(message, "data"))
                $root.fincharts.Data.encode(message.data, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.error != null && Object.hasOwnProperty.call(message, "error"))
                $root.fincharts.Error.encode(message.error, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.empty != null && Object.hasOwnProperty.call(message, "empty"))
                $root.google.protobuf.Empty.encode(message.empty, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            if (message.supportedIndicators != null && Object.hasOwnProperty.call(message, "supportedIndicators"))
                $root.fincharts.SupportedIndicators.encode(message.supportedIndicators, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            if (message.supportedStrategies != null && Object.hasOwnProperty.call(message, "supportedStrategies"))
                $root.fincharts.SupportedStrategies.encode(message.supportedStrategies, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified ServerResponse message, length delimited. Does not implicitly {@link fincharts.ServerResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof fincharts.ServerResponse
         * @static
         * @param {fincharts.IServerResponse} message ServerResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ServerResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ServerResponse message from the specified reader or buffer.
         * @function decode
         * @memberof fincharts.ServerResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {fincharts.ServerResponse} ServerResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ServerResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.fincharts.ServerResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.replyToID = reader.uint64();
                    break;
                case 2:
                    message.code = reader.int32();
                    break;
                case 3:
                    message.data = $root.fincharts.Data.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.error = $root.fincharts.Error.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.empty = $root.google.protobuf.Empty.decode(reader, reader.uint32());
                    break;
                case 6:
                    message.supportedIndicators = $root.fincharts.SupportedIndicators.decode(reader, reader.uint32());
                    break;
                case 7:
                    message.supportedStrategies = $root.fincharts.SupportedStrategies.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ServerResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof fincharts.ServerResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {fincharts.ServerResponse} ServerResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ServerResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ServerResponse message.
         * @function verify
         * @memberof fincharts.ServerResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ServerResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.replyToID != null && message.hasOwnProperty("replyToID"))
                if (!$util.isInteger(message.replyToID) && !(message.replyToID && $util.isInteger(message.replyToID.low) && $util.isInteger(message.replyToID.high)))
                    return "replyToID: integer|Long expected";
            if (message.code != null && message.hasOwnProperty("code"))
                switch (message.code) {
                default:
                    return "code: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                    break;
                }
            if (message.data != null && message.hasOwnProperty("data")) {
                properties.response = 1;
                {
                    let error = $root.fincharts.Data.verify(message.data);
                    if (error)
                        return "data." + error;
                }
            }
            if (message.error != null && message.hasOwnProperty("error")) {
                if (properties.response === 1)
                    return "response: multiple values";
                properties.response = 1;
                {
                    let error = $root.fincharts.Error.verify(message.error);
                    if (error)
                        return "error." + error;
                }
            }
            if (message.empty != null && message.hasOwnProperty("empty")) {
                if (properties.response === 1)
                    return "response: multiple values";
                properties.response = 1;
                {
                    let error = $root.google.protobuf.Empty.verify(message.empty);
                    if (error)
                        return "empty." + error;
                }
            }
            if (message.supportedIndicators != null && message.hasOwnProperty("supportedIndicators")) {
                if (properties.response === 1)
                    return "response: multiple values";
                properties.response = 1;
                {
                    let error = $root.fincharts.SupportedIndicators.verify(message.supportedIndicators);
                    if (error)
                        return "supportedIndicators." + error;
                }
            }
            if (message.supportedStrategies != null && message.hasOwnProperty("supportedStrategies")) {
                if (properties.response === 1)
                    return "response: multiple values";
                properties.response = 1;
                {
                    let error = $root.fincharts.SupportedStrategies.verify(message.supportedStrategies);
                    if (error)
                        return "supportedStrategies." + error;
                }
            }
            return null;
        };

        /**
         * Creates a ServerResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof fincharts.ServerResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {fincharts.ServerResponse} ServerResponse
         */
        ServerResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.fincharts.ServerResponse)
                return object;
            let message = new $root.fincharts.ServerResponse();
            if (object.replyToID != null)
                if ($util.Long)
                    (message.replyToID = $util.Long.fromValue(object.replyToID)).unsigned = true;
                else if (typeof object.replyToID === "string")
                    message.replyToID = parseInt(object.replyToID, 10);
                else if (typeof object.replyToID === "number")
                    message.replyToID = object.replyToID;
                else if (typeof object.replyToID === "object")
                    message.replyToID = new $util.LongBits(object.replyToID.low >>> 0, object.replyToID.high >>> 0).toNumber(true);
            switch (object.code) {
            case "OK":
            case 0:
                message.code = 0;
                break;
            case "FAIL":
            case 1:
                message.code = 1;
                break;
            case "STREAMING":
            case 2:
                message.code = 2;
                break;
            case "STREAMING_LAST":
            case 3:
                message.code = 3;
                break;
            case "NEW_STEP":
            case 4:
                message.code = 4;
                break;
            }
            if (object.data != null) {
                if (typeof object.data !== "object")
                    throw TypeError(".fincharts.ServerResponse.data: object expected");
                message.data = $root.fincharts.Data.fromObject(object.data);
            }
            if (object.error != null) {
                if (typeof object.error !== "object")
                    throw TypeError(".fincharts.ServerResponse.error: object expected");
                message.error = $root.fincharts.Error.fromObject(object.error);
            }
            if (object.empty != null) {
                if (typeof object.empty !== "object")
                    throw TypeError(".fincharts.ServerResponse.empty: object expected");
                message.empty = $root.google.protobuf.Empty.fromObject(object.empty);
            }
            if (object.supportedIndicators != null) {
                if (typeof object.supportedIndicators !== "object")
                    throw TypeError(".fincharts.ServerResponse.supportedIndicators: object expected");
                message.supportedIndicators = $root.fincharts.SupportedIndicators.fromObject(object.supportedIndicators);
            }
            if (object.supportedStrategies != null) {
                if (typeof object.supportedStrategies !== "object")
                    throw TypeError(".fincharts.ServerResponse.supportedStrategies: object expected");
                message.supportedStrategies = $root.fincharts.SupportedStrategies.fromObject(object.supportedStrategies);
            }
            return message;
        };

        /**
         * Creates a plain object from a ServerResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof fincharts.ServerResponse
         * @static
         * @param {fincharts.ServerResponse} message ServerResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ServerResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.replyToID = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.replyToID = options.longs === String ? "0" : 0;
                object.code = options.enums === String ? "OK" : 0;
            }
            if (message.replyToID != null && message.hasOwnProperty("replyToID"))
                if (typeof message.replyToID === "number")
                    object.replyToID = options.longs === String ? String(message.replyToID) : message.replyToID;
                else
                    object.replyToID = options.longs === String ? $util.Long.prototype.toString.call(message.replyToID) : options.longs === Number ? new $util.LongBits(message.replyToID.low >>> 0, message.replyToID.high >>> 0).toNumber(true) : message.replyToID;
            if (message.code != null && message.hasOwnProperty("code"))
                object.code = options.enums === String ? $root.fincharts.ServerResponse.Code[message.code] : message.code;
            if (message.data != null && message.hasOwnProperty("data")) {
                object.data = $root.fincharts.Data.toObject(message.data, options);
                if (options.oneofs)
                    object.response = "data";
            }
            if (message.error != null && message.hasOwnProperty("error")) {
                object.error = $root.fincharts.Error.toObject(message.error, options);
                if (options.oneofs)
                    object.response = "error";
            }
            if (message.empty != null && message.hasOwnProperty("empty")) {
                object.empty = $root.google.protobuf.Empty.toObject(message.empty, options);
                if (options.oneofs)
                    object.response = "empty";
            }
            if (message.supportedIndicators != null && message.hasOwnProperty("supportedIndicators")) {
                object.supportedIndicators = $root.fincharts.SupportedIndicators.toObject(message.supportedIndicators, options);
                if (options.oneofs)
                    object.response = "supportedIndicators";
            }
            if (message.supportedStrategies != null && message.hasOwnProperty("supportedStrategies")) {
                object.supportedStrategies = $root.fincharts.SupportedStrategies.toObject(message.supportedStrategies, options);
                if (options.oneofs)
                    object.response = "supportedStrategies";
            }
            return object;
        };

        /**
         * Converts this ServerResponse to JSON.
         * @function toJSON
         * @memberof fincharts.ServerResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ServerResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Code enum.
         * @name fincharts.ServerResponse.Code
         * @enum {number}
         * @property {number} OK=0 OK value
         * @property {number} FAIL=1 FAIL value
         * @property {number} STREAMING=2 STREAMING value
         * @property {number} STREAMING_LAST=3 STREAMING_LAST value
         * @property {number} NEW_STEP=4 NEW_STEP value
         */
        ServerResponse.Code = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "OK"] = 0;
            values[valuesById[1] = "FAIL"] = 1;
            values[valuesById[2] = "STREAMING"] = 2;
            values[valuesById[3] = "STREAMING_LAST"] = 3;
            values[valuesById[4] = "NEW_STEP"] = 4;
            return values;
        })();

        return ServerResponse;
    })();

    /**
     * TimeStep enum.
     * @name fincharts.TimeStep
     * @enum {number}
     * @property {number} t1sec=0 t1sec value
     * @property {number} t5sec=1 t5sec value
     * @property {number} t15sec=2 t15sec value
     * @property {number} t30sec=3 t30sec value
     * @property {number} t1min=4 t1min value
     * @property {number} t2min=5 t2min value
     * @property {number} t3min=6 t3min value
     * @property {number} t4min=7 t4min value
     * @property {number} t5min=8 t5min value
     * @property {number} t10min=9 t10min value
     * @property {number} t15min=10 t15min value
     * @property {number} t20min=11 t20min value
     * @property {number} t25min=12 t25min value
     * @property {number} t30min=13 t30min value
     * @property {number} t45min=14 t45min value
     * @property {number} t1h=15 t1h value
     * @property {number} t90min=16 t90min value
     * @property {number} t2h=17 t2h value
     * @property {number} t3h=18 t3h value
     * @property {number} t4h=19 t4h value
     * @property {number} t5h=20 t5h value
     * @property {number} t6h=21 t6h value
     * @property {number} t7h=22 t7h value
     * @property {number} t8h=23 t8h value
     * @property {number} t9h=24 t9h value
     * @property {number} t10h=25 t10h value
     * @property {number} t11h=26 t11h value
     * @property {number} t12h=27 t12h value
     * @property {number} t13h=28 t13h value
     * @property {number} t14h=29 t14h value
     * @property {number} t15h=30 t15h value
     * @property {number} t16h=31 t16h value
     * @property {number} t17h=32 t17h value
     * @property {number} t18h=33 t18h value
     * @property {number} t19h=34 t19h value
     * @property {number} t20h=35 t20h value
     * @property {number} t21h=36 t21h value
     * @property {number} t22h=37 t22h value
     * @property {number} t23h=38 t23h value
     * @property {number} t1day=39 t1day value
     * @property {number} t30h=40 t30h value
     * @property {number} t36h=41 t36h value
     * @property {number} t42h=42 t42h value
     * @property {number} t2day=43 t2day value
     * @property {number} t60h=44 t60h value
     * @property {number} t3day=45 t3day value
     * @property {number} t4day=46 t4day value
     * @property {number} t5day=47 t5day value
     * @property {number} t6day=48 t6day value
     * @property {number} t1week=49 t1week value
     * @property {number} t7day=50 t7day value
     * @property {number} t8day=51 t8day value
     * @property {number} t9day=52 t9day value
     * @property {number} t10day=53 t10day value
     * @property {number} t11day=54 t11day value
     * @property {number} t12day=55 t12day value
     * @property {number} t13day=56 t13day value
     * @property {number} t14day=57 t14day value
     * @property {number} t15day=58 t15day value
     * @property {number} t16day=59 t16day value
     * @property {number} t17day=60 t17day value
     * @property {number} t18day=61 t18day value
     * @property {number} t19day=62 t19day value
     * @property {number} t20day=63 t20day value
     * @property {number} t21day=64 t21day value
     * @property {number} t22day=65 t22day value
     * @property {number} t23day=66 t23day value
     * @property {number} t24day=67 t24day value
     * @property {number} t25day=68 t25day value
     * @property {number} t26day=69 t26day value
     * @property {number} t27day=70 t27day value
     * @property {number} t28day=71 t28day value
     * @property {number} t29day=72 t29day value
     * @property {number} t30day=73 t30day value
     * @property {number} t1m=74 t1m value
     * @property {number} t45day=75 t45day value
     * @property {number} t60day=76 t60day value
     * @property {number} t75day=77 t75day value
     * @property {number} t3m=78 t3m value
     * @property {number} t120day=79 t120day value
     * @property {number} t150day=80 t150day value
     * @property {number} t6m=81 t6m value
     * @property {number} t210day=82 t210day value
     * @property {number} t240day=83 t240day value
     * @property {number} t270day=84 t270day value
     * @property {number} t300day=85 t300day value
     * @property {number} t330day=86 t330day value
     * @property {number} t1y=87 t1y value
     * @property {number} t18m=88 t18m value
     * @property {number} t2y=89 t2y value
     */
    fincharts.TimeStep = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "t1sec"] = 0;
        values[valuesById[1] = "t5sec"] = 1;
        values[valuesById[2] = "t15sec"] = 2;
        values[valuesById[3] = "t30sec"] = 3;
        values[valuesById[4] = "t1min"] = 4;
        values[valuesById[5] = "t2min"] = 5;
        values[valuesById[6] = "t3min"] = 6;
        values[valuesById[7] = "t4min"] = 7;
        values[valuesById[8] = "t5min"] = 8;
        values[valuesById[9] = "t10min"] = 9;
        values[valuesById[10] = "t15min"] = 10;
        values[valuesById[11] = "t20min"] = 11;
        values[valuesById[12] = "t25min"] = 12;
        values[valuesById[13] = "t30min"] = 13;
        values[valuesById[14] = "t45min"] = 14;
        values[valuesById[15] = "t1h"] = 15;
        values[valuesById[16] = "t90min"] = 16;
        values[valuesById[17] = "t2h"] = 17;
        values[valuesById[18] = "t3h"] = 18;
        values[valuesById[19] = "t4h"] = 19;
        values[valuesById[20] = "t5h"] = 20;
        values[valuesById[21] = "t6h"] = 21;
        values[valuesById[22] = "t7h"] = 22;
        values[valuesById[23] = "t8h"] = 23;
        values[valuesById[24] = "t9h"] = 24;
        values[valuesById[25] = "t10h"] = 25;
        values[valuesById[26] = "t11h"] = 26;
        values[valuesById[27] = "t12h"] = 27;
        values[valuesById[28] = "t13h"] = 28;
        values[valuesById[29] = "t14h"] = 29;
        values[valuesById[30] = "t15h"] = 30;
        values[valuesById[31] = "t16h"] = 31;
        values[valuesById[32] = "t17h"] = 32;
        values[valuesById[33] = "t18h"] = 33;
        values[valuesById[34] = "t19h"] = 34;
        values[valuesById[35] = "t20h"] = 35;
        values[valuesById[36] = "t21h"] = 36;
        values[valuesById[37] = "t22h"] = 37;
        values[valuesById[38] = "t23h"] = 38;
        values[valuesById[39] = "t1day"] = 39;
        values[valuesById[40] = "t30h"] = 40;
        values[valuesById[41] = "t36h"] = 41;
        values[valuesById[42] = "t42h"] = 42;
        values[valuesById[43] = "t2day"] = 43;
        values[valuesById[44] = "t60h"] = 44;
        values[valuesById[45] = "t3day"] = 45;
        values[valuesById[46] = "t4day"] = 46;
        values[valuesById[47] = "t5day"] = 47;
        values[valuesById[48] = "t6day"] = 48;
        values[valuesById[49] = "t1week"] = 49;
        values[valuesById[50] = "t7day"] = 50;
        values[valuesById[51] = "t8day"] = 51;
        values[valuesById[52] = "t9day"] = 52;
        values[valuesById[53] = "t10day"] = 53;
        values[valuesById[54] = "t11day"] = 54;
        values[valuesById[55] = "t12day"] = 55;
        values[valuesById[56] = "t13day"] = 56;
        values[valuesById[57] = "t14day"] = 57;
        values[valuesById[58] = "t15day"] = 58;
        values[valuesById[59] = "t16day"] = 59;
        values[valuesById[60] = "t17day"] = 60;
        values[valuesById[61] = "t18day"] = 61;
        values[valuesById[62] = "t19day"] = 62;
        values[valuesById[63] = "t20day"] = 63;
        values[valuesById[64] = "t21day"] = 64;
        values[valuesById[65] = "t22day"] = 65;
        values[valuesById[66] = "t23day"] = 66;
        values[valuesById[67] = "t24day"] = 67;
        values[valuesById[68] = "t25day"] = 68;
        values[valuesById[69] = "t26day"] = 69;
        values[valuesById[70] = "t27day"] = 70;
        values[valuesById[71] = "t28day"] = 71;
        values[valuesById[72] = "t29day"] = 72;
        values[valuesById[73] = "t30day"] = 73;
        values[valuesById[74] = "t1m"] = 74;
        values[valuesById[75] = "t45day"] = 75;
        values[valuesById[76] = "t60day"] = 76;
        values[valuesById[77] = "t75day"] = 77;
        values[valuesById[78] = "t3m"] = 78;
        values[valuesById[79] = "t120day"] = 79;
        values[valuesById[80] = "t150day"] = 80;
        values[valuesById[81] = "t6m"] = 81;
        values[valuesById[82] = "t210day"] = 82;
        values[valuesById[83] = "t240day"] = 83;
        values[valuesById[84] = "t270day"] = 84;
        values[valuesById[85] = "t300day"] = 85;
        values[valuesById[86] = "t330day"] = 86;
        values[valuesById[87] = "t1y"] = 87;
        values[valuesById[88] = "t18m"] = 88;
        values[valuesById[89] = "t2y"] = 89;
        return values;
    })();

    /**
     * SymbolCategory enum.
     * @name fincharts.SymbolCategory
     * @enum {number}
     * @property {number} EquityUS=0 EquityUS value
     * @property {number} EquityNonUS=1 EquityNonUS value
     * @property {number} Forex=2 Forex value
     * @property {number} Crypto=3 Crypto value
     */
    fincharts.SymbolCategory = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "EquityUS"] = 0;
        values[valuesById[1] = "EquityNonUS"] = 1;
        values[valuesById[2] = "Forex"] = 2;
        values[valuesById[3] = "Crypto"] = 3;
        return values;
    })();

    fincharts.Symbol = (function() {

        /**
         * Properties of a Symbol.
         * @memberof fincharts
         * @interface ISymbol
         * @property {fincharts.SymbolCategory|null} [category] Symbol category
         * @property {string|null} [name] Symbol name
         */

        /**
         * Constructs a new Symbol.
         * @memberof fincharts
         * @classdesc Represents a Symbol.
         * @implements ISymbol
         * @constructor
         * @param {fincharts.ISymbol=} [properties] Properties to set
         */
        function Symbol(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Symbol category.
         * @member {fincharts.SymbolCategory} category
         * @memberof fincharts.Symbol
         * @instance
         */
        Symbol.prototype.category = 0;

        /**
         * Symbol name.
         * @member {string} name
         * @memberof fincharts.Symbol
         * @instance
         */
        Symbol.prototype.name = "";

        /**
         * Creates a new Symbol instance using the specified properties.
         * @function create
         * @memberof fincharts.Symbol
         * @static
         * @param {fincharts.ISymbol=} [properties] Properties to set
         * @returns {fincharts.Symbol} Symbol instance
         */
        Symbol.create = function create(properties) {
            return new Symbol(properties);
        };

        /**
         * Encodes the specified Symbol message. Does not implicitly {@link fincharts.Symbol.verify|verify} messages.
         * @function encode
         * @memberof fincharts.Symbol
         * @static
         * @param {fincharts.ISymbol} message Symbol message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Symbol.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.category != null && Object.hasOwnProperty.call(message, "category"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.category);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
            return writer;
        };

        /**
         * Encodes the specified Symbol message, length delimited. Does not implicitly {@link fincharts.Symbol.verify|verify} messages.
         * @function encodeDelimited
         * @memberof fincharts.Symbol
         * @static
         * @param {fincharts.ISymbol} message Symbol message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Symbol.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Symbol message from the specified reader or buffer.
         * @function decode
         * @memberof fincharts.Symbol
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {fincharts.Symbol} Symbol
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Symbol.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.fincharts.Symbol();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.category = reader.int32();
                    break;
                case 2:
                    message.name = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Symbol message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof fincharts.Symbol
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {fincharts.Symbol} Symbol
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Symbol.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Symbol message.
         * @function verify
         * @memberof fincharts.Symbol
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Symbol.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.category != null && message.hasOwnProperty("category"))
                switch (message.category) {
                default:
                    return "category: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                    break;
                }
            if (message.name != null && message.hasOwnProperty("name"))
                if (!$util.isString(message.name))
                    return "name: string expected";
            return null;
        };

        /**
         * Creates a Symbol message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof fincharts.Symbol
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {fincharts.Symbol} Symbol
         */
        Symbol.fromObject = function fromObject(object) {
            if (object instanceof $root.fincharts.Symbol)
                return object;
            let message = new $root.fincharts.Symbol();
            switch (object.category) {
            case "EquityUS":
            case 0:
                message.category = 0;
                break;
            case "EquityNonUS":
            case 1:
                message.category = 1;
                break;
            case "Forex":
            case 2:
                message.category = 2;
                break;
            case "Crypto":
            case 3:
                message.category = 3;
                break;
            }
            if (object.name != null)
                message.name = String(object.name);
            return message;
        };

        /**
         * Creates a plain object from a Symbol message. Also converts values to other types if specified.
         * @function toObject
         * @memberof fincharts.Symbol
         * @static
         * @param {fincharts.Symbol} message Symbol
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Symbol.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.category = options.enums === String ? "EquityUS" : 0;
                object.name = "";
            }
            if (message.category != null && message.hasOwnProperty("category"))
                object.category = options.enums === String ? $root.fincharts.SymbolCategory[message.category] : message.category;
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            return object;
        };

        /**
         * Converts this Symbol to JSON.
         * @function toJSON
         * @memberof fincharts.Symbol
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Symbol.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return Symbol;
    })();

    fincharts.Data = (function() {

        /**
         * Properties of a Data.
         * @memberof fincharts
         * @interface IData
         * @property {google.protobuf.ITimestamp|null} [timestamp] Data timestamp
         * @property {number|null} [open] Data open
         * @property {number|null} [high] Data high
         * @property {number|null} [low] Data low
         * @property {number|null} [close] Data close
         * @property {number|Long|null} [volume] Data volume
         * @property {Array.<fincharts.Data.SignalType>|null} [signal] Data signal
         * @property {Array.<fincharts.Data.IIndicatorValues>|null} [indicators] Data indicators
         * @property {Array.<number>|null} [trendStrength] Data trendStrength
         * @property {number|null} [bid] Data bid
         * @property {number|null} [ask] Data ask
         * @property {number|null} [size] Data size
         */

        /**
         * Constructs a new Data.
         * @memberof fincharts
         * @classdesc Represents a Data.
         * @implements IData
         * @constructor
         * @param {fincharts.IData=} [properties] Properties to set
         */
        function Data(properties) {
            this.signal = [];
            this.indicators = [];
            this.trendStrength = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Data timestamp.
         * @member {google.protobuf.ITimestamp|null|undefined} timestamp
         * @memberof fincharts.Data
         * @instance
         */
        Data.prototype.timestamp = null;

        /**
         * Data open.
         * @member {number} open
         * @memberof fincharts.Data
         * @instance
         */
        Data.prototype.open = 0;

        /**
         * Data high.
         * @member {number} high
         * @memberof fincharts.Data
         * @instance
         */
        Data.prototype.high = 0;

        /**
         * Data low.
         * @member {number} low
         * @memberof fincharts.Data
         * @instance
         */
        Data.prototype.low = 0;

        /**
         * Data close.
         * @member {number} close
         * @memberof fincharts.Data
         * @instance
         */
        Data.prototype.close = 0;

        /**
         * Data volume.
         * @member {number|Long} volume
         * @memberof fincharts.Data
         * @instance
         */
        Data.prototype.volume = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Data signal.
         * @member {Array.<fincharts.Data.SignalType>} signal
         * @memberof fincharts.Data
         * @instance
         */
        Data.prototype.signal = $util.emptyArray;

        /**
         * Data indicators.
         * @member {Array.<fincharts.Data.IIndicatorValues>} indicators
         * @memberof fincharts.Data
         * @instance
         */
        Data.prototype.indicators = $util.emptyArray;

        /**
         * Data trendStrength.
         * @member {Array.<number>} trendStrength
         * @memberof fincharts.Data
         * @instance
         */
        Data.prototype.trendStrength = $util.emptyArray;

        /**
         * Data bid.
         * @member {number} bid
         * @memberof fincharts.Data
         * @instance
         */
        Data.prototype.bid = 0;

        /**
         * Data ask.
         * @member {number} ask
         * @memberof fincharts.Data
         * @instance
         */
        Data.prototype.ask = 0;

        /**
         * Data size.
         * @member {number} size
         * @memberof fincharts.Data
         * @instance
         */
        Data.prototype.size = 0;

        /**
         * Creates a new Data instance using the specified properties.
         * @function create
         * @memberof fincharts.Data
         * @static
         * @param {fincharts.IData=} [properties] Properties to set
         * @returns {fincharts.Data} Data instance
         */
        Data.create = function create(properties) {
            return new Data(properties);
        };

        /**
         * Encodes the specified Data message. Does not implicitly {@link fincharts.Data.verify|verify} messages.
         * @function encode
         * @memberof fincharts.Data
         * @static
         * @param {fincharts.IData} message Data message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Data.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.timestamp != null && Object.hasOwnProperty.call(message, "timestamp"))
                $root.google.protobuf.Timestamp.encode(message.timestamp, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.open != null && Object.hasOwnProperty.call(message, "open"))
                writer.uint32(/* id 2, wireType 5 =*/21).float(message.open);
            if (message.high != null && Object.hasOwnProperty.call(message, "high"))
                writer.uint32(/* id 3, wireType 5 =*/29).float(message.high);
            if (message.low != null && Object.hasOwnProperty.call(message, "low"))
                writer.uint32(/* id 4, wireType 5 =*/37).float(message.low);
            if (message.close != null && Object.hasOwnProperty.call(message, "close"))
                writer.uint32(/* id 5, wireType 5 =*/45).float(message.close);
            if (message.volume != null && Object.hasOwnProperty.call(message, "volume"))
                writer.uint32(/* id 6, wireType 0 =*/48).int64(message.volume);
            if (message.signal != null && message.signal.length) {
                writer.uint32(/* id 7, wireType 2 =*/58).fork();
                for (let i = 0; i < message.signal.length; ++i)
                    writer.int32(message.signal[i]);
                writer.ldelim();
            }
            if (message.indicators != null && message.indicators.length)
                for (let i = 0; i < message.indicators.length; ++i)
                    $root.fincharts.Data.IndicatorValues.encode(message.indicators[i], writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
            if (message.trendStrength != null && message.trendStrength.length) {
                writer.uint32(/* id 9, wireType 2 =*/74).fork();
                for (let i = 0; i < message.trendStrength.length; ++i)
                    writer.float(message.trendStrength[i]);
                writer.ldelim();
            }
            if (message.bid != null && Object.hasOwnProperty.call(message, "bid"))
                writer.uint32(/* id 10, wireType 5 =*/85).float(message.bid);
            if (message.ask != null && Object.hasOwnProperty.call(message, "ask"))
                writer.uint32(/* id 11, wireType 5 =*/93).float(message.ask);
            if (message.size != null && Object.hasOwnProperty.call(message, "size"))
                writer.uint32(/* id 12, wireType 0 =*/96).int32(message.size);
            return writer;
        };

        /**
         * Encodes the specified Data message, length delimited. Does not implicitly {@link fincharts.Data.verify|verify} messages.
         * @function encodeDelimited
         * @memberof fincharts.Data
         * @static
         * @param {fincharts.IData} message Data message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Data.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Data message from the specified reader or buffer.
         * @function decode
         * @memberof fincharts.Data
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {fincharts.Data} Data
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Data.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.fincharts.Data();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.timestamp = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.open = reader.float();
                    break;
                case 3:
                    message.high = reader.float();
                    break;
                case 4:
                    message.low = reader.float();
                    break;
                case 5:
                    message.close = reader.float();
                    break;
                case 6:
                    message.volume = reader.int64();
                    break;
                case 7:
                    if (!(message.signal && message.signal.length))
                        message.signal = [];
                    if ((tag & 7) === 2) {
                        let end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2)
                            message.signal.push(reader.int32());
                    } else
                        message.signal.push(reader.int32());
                    break;
                case 8:
                    if (!(message.indicators && message.indicators.length))
                        message.indicators = [];
                    message.indicators.push($root.fincharts.Data.IndicatorValues.decode(reader, reader.uint32()));
                    break;
                case 9:
                    if (!(message.trendStrength && message.trendStrength.length))
                        message.trendStrength = [];
                    if ((tag & 7) === 2) {
                        let end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2)
                            message.trendStrength.push(reader.float());
                    } else
                        message.trendStrength.push(reader.float());
                    break;
                case 10:
                    message.bid = reader.float();
                    break;
                case 11:
                    message.ask = reader.float();
                    break;
                case 12:
                    message.size = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Data message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof fincharts.Data
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {fincharts.Data} Data
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Data.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Data message.
         * @function verify
         * @memberof fincharts.Data
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Data.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.timestamp != null && message.hasOwnProperty("timestamp")) {
                let error = $root.google.protobuf.Timestamp.verify(message.timestamp);
                if (error)
                    return "timestamp." + error;
            }
            if (message.open != null && message.hasOwnProperty("open"))
                if (typeof message.open !== "number")
                    return "open: number expected";
            if (message.high != null && message.hasOwnProperty("high"))
                if (typeof message.high !== "number")
                    return "high: number expected";
            if (message.low != null && message.hasOwnProperty("low"))
                if (typeof message.low !== "number")
                    return "low: number expected";
            if (message.close != null && message.hasOwnProperty("close"))
                if (typeof message.close !== "number")
                    return "close: number expected";
            if (message.volume != null && message.hasOwnProperty("volume"))
                if (!$util.isInteger(message.volume) && !(message.volume && $util.isInteger(message.volume.low) && $util.isInteger(message.volume.high)))
                    return "volume: integer|Long expected";
            if (message.signal != null && message.hasOwnProperty("signal")) {
                if (!Array.isArray(message.signal))
                    return "signal: array expected";
                for (let i = 0; i < message.signal.length; ++i)
                    switch (message.signal[i]) {
                    default:
                        return "signal: enum value[] expected";
                    case 0:
                    case 1:
                    case 2:
                        break;
                    }
            }
            if (message.indicators != null && message.hasOwnProperty("indicators")) {
                if (!Array.isArray(message.indicators))
                    return "indicators: array expected";
                for (let i = 0; i < message.indicators.length; ++i) {
                    let error = $root.fincharts.Data.IndicatorValues.verify(message.indicators[i]);
                    if (error)
                        return "indicators." + error;
                }
            }
            if (message.trendStrength != null && message.hasOwnProperty("trendStrength")) {
                if (!Array.isArray(message.trendStrength))
                    return "trendStrength: array expected";
                for (let i = 0; i < message.trendStrength.length; ++i)
                    if (typeof message.trendStrength[i] !== "number")
                        return "trendStrength: number[] expected";
            }
            if (message.bid != null && message.hasOwnProperty("bid"))
                if (typeof message.bid !== "number")
                    return "bid: number expected";
            if (message.ask != null && message.hasOwnProperty("ask"))
                if (typeof message.ask !== "number")
                    return "ask: number expected";
            if (message.size != null && message.hasOwnProperty("size"))
                if (!$util.isInteger(message.size))
                    return "size: integer expected";
            return null;
        };

        /**
         * Creates a Data message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof fincharts.Data
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {fincharts.Data} Data
         */
        Data.fromObject = function fromObject(object) {
            if (object instanceof $root.fincharts.Data)
                return object;
            let message = new $root.fincharts.Data();
            if (object.timestamp != null) {
                if (typeof object.timestamp !== "object")
                    throw TypeError(".fincharts.Data.timestamp: object expected");
                message.timestamp = $root.google.protobuf.Timestamp.fromObject(object.timestamp);
            }
            if (object.open != null)
                message.open = Number(object.open);
            if (object.high != null)
                message.high = Number(object.high);
            if (object.low != null)
                message.low = Number(object.low);
            if (object.close != null)
                message.close = Number(object.close);
            if (object.volume != null)
                if ($util.Long)
                    (message.volume = $util.Long.fromValue(object.volume)).unsigned = false;
                else if (typeof object.volume === "string")
                    message.volume = parseInt(object.volume, 10);
                else if (typeof object.volume === "number")
                    message.volume = object.volume;
                else if (typeof object.volume === "object")
                    message.volume = new $util.LongBits(object.volume.low >>> 0, object.volume.high >>> 0).toNumber();
            if (object.signal) {
                if (!Array.isArray(object.signal))
                    throw TypeError(".fincharts.Data.signal: array expected");
                message.signal = [];
                for (let i = 0; i < object.signal.length; ++i)
                    switch (object.signal[i]) {
                    default:
                    case "nothing":
                    case 0:
                        message.signal[i] = 0;
                        break;
                    case "buy":
                    case 1:
                        message.signal[i] = 1;
                        break;
                    case "sell":
                    case 2:
                        message.signal[i] = 2;
                        break;
                    }
            }
            if (object.indicators) {
                if (!Array.isArray(object.indicators))
                    throw TypeError(".fincharts.Data.indicators: array expected");
                message.indicators = [];
                for (let i = 0; i < object.indicators.length; ++i) {
                    if (typeof object.indicators[i] !== "object")
                        throw TypeError(".fincharts.Data.indicators: object expected");
                    message.indicators[i] = $root.fincharts.Data.IndicatorValues.fromObject(object.indicators[i]);
                }
            }
            if (object.trendStrength) {
                if (!Array.isArray(object.trendStrength))
                    throw TypeError(".fincharts.Data.trendStrength: array expected");
                message.trendStrength = [];
                for (let i = 0; i < object.trendStrength.length; ++i)
                    message.trendStrength[i] = Number(object.trendStrength[i]);
            }
            if (object.bid != null)
                message.bid = Number(object.bid);
            if (object.ask != null)
                message.ask = Number(object.ask);
            if (object.size != null)
                message.size = object.size | 0;
            return message;
        };

        /**
         * Creates a plain object from a Data message. Also converts values to other types if specified.
         * @function toObject
         * @memberof fincharts.Data
         * @static
         * @param {fincharts.Data} message Data
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Data.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults) {
                object.signal = [];
                object.indicators = [];
                object.trendStrength = [];
            }
            if (options.defaults) {
                object.timestamp = null;
                object.open = 0;
                object.high = 0;
                object.low = 0;
                object.close = 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.volume = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.volume = options.longs === String ? "0" : 0;
                object.bid = 0;
                object.ask = 0;
                object.size = 0;
            }
            if (message.timestamp != null && message.hasOwnProperty("timestamp"))
                object.timestamp = $root.google.protobuf.Timestamp.toObject(message.timestamp, options);
            if (message.open != null && message.hasOwnProperty("open"))
                object.open = options.json && !isFinite(message.open) ? String(message.open) : message.open;
            if (message.high != null && message.hasOwnProperty("high"))
                object.high = options.json && !isFinite(message.high) ? String(message.high) : message.high;
            if (message.low != null && message.hasOwnProperty("low"))
                object.low = options.json && !isFinite(message.low) ? String(message.low) : message.low;
            if (message.close != null && message.hasOwnProperty("close"))
                object.close = options.json && !isFinite(message.close) ? String(message.close) : message.close;
            if (message.volume != null && message.hasOwnProperty("volume"))
                if (typeof message.volume === "number")
                    object.volume = options.longs === String ? String(message.volume) : message.volume;
                else
                    object.volume = options.longs === String ? $util.Long.prototype.toString.call(message.volume) : options.longs === Number ? new $util.LongBits(message.volume.low >>> 0, message.volume.high >>> 0).toNumber() : message.volume;
            if (message.signal && message.signal.length) {
                object.signal = [];
                for (let j = 0; j < message.signal.length; ++j)
                    object.signal[j] = options.enums === String ? $root.fincharts.Data.SignalType[message.signal[j]] : message.signal[j];
            }
            if (message.indicators && message.indicators.length) {
                object.indicators = [];
                for (let j = 0; j < message.indicators.length; ++j)
                    object.indicators[j] = $root.fincharts.Data.IndicatorValues.toObject(message.indicators[j], options);
            }
            if (message.trendStrength && message.trendStrength.length) {
                object.trendStrength = [];
                for (let j = 0; j < message.trendStrength.length; ++j)
                    object.trendStrength[j] = options.json && !isFinite(message.trendStrength[j]) ? String(message.trendStrength[j]) : message.trendStrength[j];
            }
            if (message.bid != null && message.hasOwnProperty("bid"))
                object.bid = options.json && !isFinite(message.bid) ? String(message.bid) : message.bid;
            if (message.ask != null && message.hasOwnProperty("ask"))
                object.ask = options.json && !isFinite(message.ask) ? String(message.ask) : message.ask;
            if (message.size != null && message.hasOwnProperty("size"))
                object.size = message.size;
            return object;
        };

        /**
         * Converts this Data to JSON.
         * @function toJSON
         * @memberof fincharts.Data
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Data.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * SignalType enum.
         * @name fincharts.Data.SignalType
         * @enum {number}
         * @property {number} nothing=0 nothing value
         * @property {number} buy=1 buy value
         * @property {number} sell=2 sell value
         */
        Data.SignalType = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "nothing"] = 0;
            values[valuesById[1] = "buy"] = 1;
            values[valuesById[2] = "sell"] = 2;
            return values;
        })();

        Data.IndicatorValues = (function() {

            /**
             * Properties of an IndicatorValues.
             * @memberof fincharts.Data
             * @interface IIndicatorValues
             * @property {Array.<number>|null} [indicatorValues] IndicatorValues indicatorValues
             */

            /**
             * Constructs a new IndicatorValues.
             * @memberof fincharts.Data
             * @classdesc Represents an IndicatorValues.
             * @implements IIndicatorValues
             * @constructor
             * @param {fincharts.Data.IIndicatorValues=} [properties] Properties to set
             */
            function IndicatorValues(properties) {
                this.indicatorValues = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * IndicatorValues indicatorValues.
             * @member {Array.<number>} indicatorValues
             * @memberof fincharts.Data.IndicatorValues
             * @instance
             */
            IndicatorValues.prototype.indicatorValues = $util.emptyArray;

            /**
             * Creates a new IndicatorValues instance using the specified properties.
             * @function create
             * @memberof fincharts.Data.IndicatorValues
             * @static
             * @param {fincharts.Data.IIndicatorValues=} [properties] Properties to set
             * @returns {fincharts.Data.IndicatorValues} IndicatorValues instance
             */
            IndicatorValues.create = function create(properties) {
                return new IndicatorValues(properties);
            };

            /**
             * Encodes the specified IndicatorValues message. Does not implicitly {@link fincharts.Data.IndicatorValues.verify|verify} messages.
             * @function encode
             * @memberof fincharts.Data.IndicatorValues
             * @static
             * @param {fincharts.Data.IIndicatorValues} message IndicatorValues message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            IndicatorValues.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.indicatorValues != null && message.indicatorValues.length) {
                    writer.uint32(/* id 1, wireType 2 =*/10).fork();
                    for (let i = 0; i < message.indicatorValues.length; ++i)
                        writer.float(message.indicatorValues[i]);
                    writer.ldelim();
                }
                return writer;
            };

            /**
             * Encodes the specified IndicatorValues message, length delimited. Does not implicitly {@link fincharts.Data.IndicatorValues.verify|verify} messages.
             * @function encodeDelimited
             * @memberof fincharts.Data.IndicatorValues
             * @static
             * @param {fincharts.Data.IIndicatorValues} message IndicatorValues message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            IndicatorValues.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an IndicatorValues message from the specified reader or buffer.
             * @function decode
             * @memberof fincharts.Data.IndicatorValues
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fincharts.Data.IndicatorValues} IndicatorValues
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            IndicatorValues.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.fincharts.Data.IndicatorValues();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        if (!(message.indicatorValues && message.indicatorValues.length))
                            message.indicatorValues = [];
                        if ((tag & 7) === 2) {
                            let end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.indicatorValues.push(reader.float());
                        } else
                            message.indicatorValues.push(reader.float());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an IndicatorValues message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof fincharts.Data.IndicatorValues
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {fincharts.Data.IndicatorValues} IndicatorValues
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            IndicatorValues.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an IndicatorValues message.
             * @function verify
             * @memberof fincharts.Data.IndicatorValues
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            IndicatorValues.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.indicatorValues != null && message.hasOwnProperty("indicatorValues")) {
                    if (!Array.isArray(message.indicatorValues))
                        return "indicatorValues: array expected";
                    for (let i = 0; i < message.indicatorValues.length; ++i)
                        if (typeof message.indicatorValues[i] !== "number")
                            return "indicatorValues: number[] expected";
                }
                return null;
            };

            /**
             * Creates an IndicatorValues message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fincharts.Data.IndicatorValues
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fincharts.Data.IndicatorValues} IndicatorValues
             */
            IndicatorValues.fromObject = function fromObject(object) {
                if (object instanceof $root.fincharts.Data.IndicatorValues)
                    return object;
                let message = new $root.fincharts.Data.IndicatorValues();
                if (object.indicatorValues) {
                    if (!Array.isArray(object.indicatorValues))
                        throw TypeError(".fincharts.Data.IndicatorValues.indicatorValues: array expected");
                    message.indicatorValues = [];
                    for (let i = 0; i < object.indicatorValues.length; ++i)
                        message.indicatorValues[i] = Number(object.indicatorValues[i]);
                }
                return message;
            };

            /**
             * Creates a plain object from an IndicatorValues message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fincharts.Data.IndicatorValues
             * @static
             * @param {fincharts.Data.IndicatorValues} message IndicatorValues
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            IndicatorValues.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults)
                    object.indicatorValues = [];
                if (message.indicatorValues && message.indicatorValues.length) {
                    object.indicatorValues = [];
                    for (let j = 0; j < message.indicatorValues.length; ++j)
                        object.indicatorValues[j] = options.json && !isFinite(message.indicatorValues[j]) ? String(message.indicatorValues[j]) : message.indicatorValues[j];
                }
                return object;
            };

            /**
             * Converts this IndicatorValues to JSON.
             * @function toJSON
             * @memberof fincharts.Data.IndicatorValues
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            IndicatorValues.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return IndicatorValues;
        })();

        return Data;
    })();

    return fincharts;
})();

export const google = $root.google = (() => {

    /**
     * Namespace google.
     * @exports google
     * @namespace
     */
    const google = {};

    google.protobuf = (function() {

        /**
         * Namespace protobuf.
         * @memberof google
         * @namespace
         */
        const protobuf = {};

        protobuf.Timestamp = (function() {

            /**
             * Properties of a Timestamp.
             * @memberof google.protobuf
             * @interface ITimestamp
             * @property {number|Long|null} [seconds] Timestamp seconds
             * @property {number|null} [nanos] Timestamp nanos
             */

            /**
             * Constructs a new Timestamp.
             * @memberof google.protobuf
             * @classdesc Represents a Timestamp.
             * @implements ITimestamp
             * @constructor
             * @param {google.protobuf.ITimestamp=} [properties] Properties to set
             */
            function Timestamp(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Timestamp seconds.
             * @member {number|Long} seconds
             * @memberof google.protobuf.Timestamp
             * @instance
             */
            Timestamp.prototype.seconds = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * Timestamp nanos.
             * @member {number} nanos
             * @memberof google.protobuf.Timestamp
             * @instance
             */
            Timestamp.prototype.nanos = 0;

            /**
             * Creates a new Timestamp instance using the specified properties.
             * @function create
             * @memberof google.protobuf.Timestamp
             * @static
             * @param {google.protobuf.ITimestamp=} [properties] Properties to set
             * @returns {google.protobuf.Timestamp} Timestamp instance
             */
            Timestamp.create = function create(properties) {
                return new Timestamp(properties);
            };

            /**
             * Encodes the specified Timestamp message. Does not implicitly {@link google.protobuf.Timestamp.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.Timestamp
             * @static
             * @param {google.protobuf.ITimestamp} message Timestamp message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Timestamp.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.seconds != null && Object.hasOwnProperty.call(message, "seconds"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int64(message.seconds);
                if (message.nanos != null && Object.hasOwnProperty.call(message, "nanos"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.nanos);
                return writer;
            };

            /**
             * Encodes the specified Timestamp message, length delimited. Does not implicitly {@link google.protobuf.Timestamp.verify|verify} messages.
             * @function encodeDelimited
             * @memberof google.protobuf.Timestamp
             * @static
             * @param {google.protobuf.ITimestamp} message Timestamp message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Timestamp.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a Timestamp message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.Timestamp
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.Timestamp} Timestamp
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Timestamp.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.Timestamp();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.seconds = reader.int64();
                        break;
                    case 2:
                        message.nanos = reader.int32();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a Timestamp message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof google.protobuf.Timestamp
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {google.protobuf.Timestamp} Timestamp
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Timestamp.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a Timestamp message.
             * @function verify
             * @memberof google.protobuf.Timestamp
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Timestamp.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.seconds != null && message.hasOwnProperty("seconds"))
                    if (!$util.isInteger(message.seconds) && !(message.seconds && $util.isInteger(message.seconds.low) && $util.isInteger(message.seconds.high)))
                        return "seconds: integer|Long expected";
                if (message.nanos != null && message.hasOwnProperty("nanos"))
                    if (!$util.isInteger(message.nanos))
                        return "nanos: integer expected";
                return null;
            };

            /**
             * Creates a Timestamp message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.Timestamp
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.Timestamp} Timestamp
             */
            Timestamp.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.Timestamp)
                    return object;
                let message = new $root.google.protobuf.Timestamp();
                if (object.seconds != null)
                    if ($util.Long)
                        (message.seconds = $util.Long.fromValue(object.seconds)).unsigned = false;
                    else if (typeof object.seconds === "string")
                        message.seconds = parseInt(object.seconds, 10);
                    else if (typeof object.seconds === "number")
                        message.seconds = object.seconds;
                    else if (typeof object.seconds === "object")
                        message.seconds = new $util.LongBits(object.seconds.low >>> 0, object.seconds.high >>> 0).toNumber();
                if (object.nanos != null)
                    message.nanos = object.nanos | 0;
                return message;
            };

            /**
             * Creates a plain object from a Timestamp message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.Timestamp
             * @static
             * @param {google.protobuf.Timestamp} message Timestamp
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Timestamp.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, false);
                        object.seconds = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.seconds = options.longs === String ? "0" : 0;
                    object.nanos = 0;
                }
                if (message.seconds != null && message.hasOwnProperty("seconds"))
                    if (typeof message.seconds === "number")
                        object.seconds = options.longs === String ? String(message.seconds) : message.seconds;
                    else
                        object.seconds = options.longs === String ? $util.Long.prototype.toString.call(message.seconds) : options.longs === Number ? new $util.LongBits(message.seconds.low >>> 0, message.seconds.high >>> 0).toNumber() : message.seconds;
                if (message.nanos != null && message.hasOwnProperty("nanos"))
                    object.nanos = message.nanos;
                return object;
            };

            /**
             * Converts this Timestamp to JSON.
             * @function toJSON
             * @memberof google.protobuf.Timestamp
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Timestamp.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return Timestamp;
        })();

        protobuf.Empty = (function() {

            /**
             * Properties of an Empty.
             * @memberof google.protobuf
             * @interface IEmpty
             */

            /**
             * Constructs a new Empty.
             * @memberof google.protobuf
             * @classdesc Represents an Empty.
             * @implements IEmpty
             * @constructor
             * @param {google.protobuf.IEmpty=} [properties] Properties to set
             */
            function Empty(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new Empty instance using the specified properties.
             * @function create
             * @memberof google.protobuf.Empty
             * @static
             * @param {google.protobuf.IEmpty=} [properties] Properties to set
             * @returns {google.protobuf.Empty} Empty instance
             */
            Empty.create = function create(properties) {
                return new Empty(properties);
            };

            /**
             * Encodes the specified Empty message. Does not implicitly {@link google.protobuf.Empty.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.Empty
             * @static
             * @param {google.protobuf.IEmpty} message Empty message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Empty.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Encodes the specified Empty message, length delimited. Does not implicitly {@link google.protobuf.Empty.verify|verify} messages.
             * @function encodeDelimited
             * @memberof google.protobuf.Empty
             * @static
             * @param {google.protobuf.IEmpty} message Empty message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Empty.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an Empty message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.Empty
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.Empty} Empty
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Empty.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.Empty();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an Empty message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof google.protobuf.Empty
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {google.protobuf.Empty} Empty
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Empty.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an Empty message.
             * @function verify
             * @memberof google.protobuf.Empty
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Empty.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates an Empty message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.Empty
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.Empty} Empty
             */
            Empty.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.Empty)
                    return object;
                return new $root.google.protobuf.Empty();
            };

            /**
             * Creates a plain object from an Empty message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.Empty
             * @static
             * @param {google.protobuf.Empty} message Empty
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Empty.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this Empty to JSON.
             * @function toJSON
             * @memberof google.protobuf.Empty
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Empty.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return Empty;
        })();

        return protobuf;
    })();

    return google;
})();