import * as axios from "axios"

import { getToken } from './AuthenticationUtil'

const PRESERVE_SETTINGS_DEBOUNCE_INTERVAL = 1500;

let authUrlBase = "";
let currentStore = {};
let loaded = false;

let debounceTimerId = null;

export function initialize(authUrl) {
    authUrlBase = authUrl;
}

export function retrieveStore() {
    axios({
        method: "GET",
        headers: {
            Authorization: `Bearer ${getToken()}`,
        },
        url: `${authUrlBase}user/preferences`,
    })
    .then((response) => {
        if (response.data && response.data.preferences) {
            const settingsString = response.data.preferences;
            if (settingsString) {
                try {
                    currentStore = JSON.parse(settingsString);
                    let haveChange = false;
                    for (const key of Object.keys(currentStore)) {
                        const lsv = localStorage.getItem(key);
                        const csv = currentStore[key];
                        if (csv !== lsv) {
                            localStorage.setItem(key, csv);
                            haveChange = true;
                        }
                    }
                    if (haveChange) {
                        window.location.reload();
                    }
                } catch (err) {
                    console.log(err);
                }
            }
        }
        loaded = true;
    })
    .catch((err)=>{
        console.log(err);
    });
}

export function preserveStore() {
    if (debounceTimerId) {
        clearTimeout(debounceTimerId);
    }
    debounceTimerId = setTimeout(preserveStoreInternal, PRESERVE_SETTINGS_DEBOUNCE_INTERVAL);
}

function preserveStoreInternal() {
    debounceTimerId = null;
    axios({
        method: "PUT",
        headers: {
            Authorization: `Bearer ${getToken()}`,
        },
        data: currentStore,
        url: `${authUrlBase}user/preferences`,
    })
    .then(() => {})
    .catch((err)=>{
        console.log(err);
    });
}

export function setItem(name, value) {
    if (!loaded) return;
    localStorage.setItem(name, value);
    currentStore[name] = value;
    preserveStore();
}

export function getItem(name) {
    return localStorage.getItem(name);
}

export function removeItem(name) {
    localStorage.removeItem(name);
    delete currentStore[name];
    preserveStore();
}
