/*!
 *
 * Angle - Bootstrap Admin Template
 *
 * Version: 4.2.2
 * Author: @themicon_co
 * Website: http://themicon.co
 * License: https://wrapbootstrap.com/help/licenses
 *
 */

import React, { Component } from 'react';
import { ConnectedRouter } from 'connected-react-router';

// App Routes
import Routes from './Routes';

// Vendor dependencies
import "./Vendor";
// Application Styles
import './styles/bootstrap.scss';
import './styles/app.scss'

import client from "./Networking"
import {history} from "./store/store"

class App extends Component {
    constructor (props) {
        super(props)
        this.client = client
        this.client.establishConnection("vlad", "QWEfincharts123")
    }

  render() {

    // specify base href from env varible 'PUBLIC_URL'
    // use only if application isn't served from the root
    // for development it is forced to root only
    /* global PUBLIC_URL */
    const basename = process.env.NODE_ENV === 'development' ? '/' : (PUBLIC_URL || '/');

    return (
        <ConnectedRouter history={history} basename={basename}>
            <Routes />
        </ConnectedRouter>
    )
  }
}

export default App;
