import { getItem as getSettingsItem } from '../../SettingsUtil'

export const ADD_POPOUT = "ADD_POPOUT"
export const CLOSE_POPOUT = "CLOSE_POPOUT"
export const SAVE_POPUP_STATES = "SAVE_POPUP_STATES"
export const RESTORE_POPUP_STATES = "RESTORE_POPUP_STATES"

export function addPopout() {
    return {type: ADD_POPOUT, popup: {}}
}

export function closePopout(id) {
    return {type: CLOSE_POPOUT, popup: {id}}
}

export function restorePopupStates() {
    const items = getSettingsItem("popouts")
    let popups = []
    if (items)
    {
        try {
            popups = JSON.parse(items)
            popups.forEach( (p, i)=> {
                p.id = i
            });
        }
        catch(e) {

        }
    }
    return {type: RESTORE_POPUP_STATES, popups: popups}
}