import {fincharts} from './proto/ws_proto'

export const categories = [
    "EquityUS",
    "EquityNonUS",
    "Forex",
    "Crypto"
]

export const ServerResponseCode = Object.freeze({
    OK: 0,
    FAIL: 1,
    STREAMING: 2,
    STREAMING_LAST: 3,
    NEW_STEP: 4,
});

class WSSNetworkClient {

    constructor() {
        this.ws_address = process.env.WEBSOCKET_API_URL
        this.isAlive = false
        this.requests = {}
        this.socket = undefined
        this.currentRequestId = 0
        this.subscribes = {}
        this.establishConnection = this.establishConnection.bind(this)
    }

    __clientRequestInternal = (request, requestType, onSubscribe) => {
        let currentRequestId = this.currentRequestId++
        let payload = {
            ID: currentRequestId
        }
        payload[requestType] = request

        if (onSubscribe) {
            this.subscribes[currentRequestId] = onSubscribe
        }
        let promise = new Promise((resolve, reject) => {
            let errMsg = fincharts.ClientRequest.verify(payload)
            if (requestType !== "authRequest") console.log(payload) // TODO: remove this console.log entirely
            if (errMsg)
                throw Error(errMsg)

            let buffer = fincharts.ClientRequest.encode(payload).finish()
            if (this.socket && (this.isAlive || requestType === "authRequest")) {
                try {
                    this.socket.send(buffer)
                    this.requests[currentRequestId] = {resolve, reject}
                }
                catch(e) {
                    this.socket.emit('error', e)
                }
            }
            else
                reject("Connection not established")
        });
        return {promise, id: currentRequestId}
    }

    sendAuthRequest = (username, password) => {
        this.currentRequestId = 0
        return this.__clientRequestInternal({token: `${username}:${password}`}, "authRequest")
    };

    subscribe = (symbol, timestep=null, indicators=null, onSubscribe=null) => {
        const category = categories.indexOf(symbol.category)
        const sym = {
            name: symbol.id,
            category: category >= 0 ? category : 0
        }
        return this.__clientRequestInternal({symbol: sym, step: timestep, indicators}, "subscribe", onSubscribe)
    }

    cancel = (ID) => {
        delete this.subscribes[ID]
        console.log(Object.keys(this.subscribes).length)
        return this.__clientRequestInternal({ID}, "cancel")
    }

    getHistoricalData = (symbol, indicators, step, begin, end, onSubscribe) => {
        if (step < 4)
            step = 4
        const category = categories.indexOf(symbol.category)
        const sym = {
            name: symbol.id,
            category: category >= 0 ? category : 0
        }
        return this.__clientRequestInternal({symbol: sym, step, begin, end, indicators}, "getHistoricalData", onSubscribe)
    }


    establishConnection = (username, password) => {
        return new Promise( (resolve, reject) => {
            try {
                const socket = new WebSocket(this.ws_address)
                socket.binaryType = "arraybuffer"
                console.log("ws created")

                socket.addEventListener('open', (event) => {
                    this.socket = socket
                    //this.reconnectTimer = setTimeout(this.establishConnection.bind(this, username, password), 1000)
                    this.sendAuthRequest(username, password)
                        .promise
                        .then((res) => {
                            console.log("established")
                            this.isAlive  = true
                            clearTimeout(this.reconnectTimer)
                            resolve(res)
                        })
                        .catch((err) => {
                            console.log(err)
                            reject(err)
                        })
                })

                socket.addEventListener('message', (event) => {
                    //console.log('Raw data from server ', event.data)
                    let data = new Buffer(event.data)
                    let response = fincharts.ServerResponse.decode(data)
                    //console.log('Message from server ', response)
                    const replyToId = +response["replyToID"]
                    let promise = this.requests[replyToId]

                    const onSubscribe = this.subscribes[replyToId]

                    if (onSubscribe) {
                        onSubscribe(response)
                        if (response.code === 1) {
                            if (promise) {
                                promise.resolve(response)
                            }
                            delete this.subscribes[replyToId]
                            console.error(`A request with ID ${replyToId} has error: ${response.error.error}`)
                            return
                        }
                        if (response.code === 3) {
                            if (promise) {
                                promise.resolve(response)
                            }
                            delete this.subscribes[replyToId]
                        }
                    }
                    else {
                        promise.resolve(response)
                    }
                })

                socket.addEventListener('error', (event) => {
                    console.error("websocket error ", event)
                    //this.reconnectTimer = setTimeout(this.establishConnection.bind(null, username, password), 1000)
                })

                socket.addEventListener('close', (event) => {
                    console.log("Socket closed")
                    for (let v in this.requests) {
                        this.requests[v].reject(event)
                    }
                    this.subscribes = {}
                    this.requests = {}
                    this.isAlive = false
                    this.reconnectTimer = setTimeout(this.establishConnection.bind(null, username, password), 1000)
                })
            }
            catch (e) {
                console.log(e)
                reject(e)
            }
        })
    };
}

const client = new WSSNetworkClient()
export default client