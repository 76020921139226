import * as React from "react"
import LayoutCommon from "./LayoutCommon"
//import 'golden-layout/src/css/goldenlayout-base.css';
//import 'golden-layout/src/css/goldenlayout-dark-theme.css';

export const periods = {
    IntraDay1: {
        charts: [
            {
                value: 1,
                unit: "Minute",
                step: "t1min"
            },
            {
                value: 2,
                unit: "Minute",
                step: "t2min"
            },
            {
                value: 3,
                unit: "Minute",
                step: "t3min"
            },
            {
                value: 4,
                unit: "Minute",
                step: "t4min"
            },
            {
                value: 5,
                unit: "Minute",
                step: "t5min"
            },
            {
                value: 10,
                unit: "Minute",
                step: "t10min"
            },
            {
                value: 15,
                unit: "Minute",
                step: "t15min"
            },
            {
                value: 20,
                unit: "Minute",
                step: "t20min"
            },
            {
                value: 25,
                unit: "Minute",
                step: "t25min"
            },
            {
                value: 30,
                unit: "Minute",
                step: "t30min"
            },
        ]
    },
    IntraDay2: {
        charts: [
            {
                value: 45,
                unit: "Minute",
                step: "t45min"
            },
            {
                value: 1,
                unit: "Hour",
                step: "t1h"
            },
            {
                value: 1.5,
                unit: "Hour",
                step: "t90min"
            },
            {
                value: 2,
                unit: "Hour",
                step: "t2h"
            },
            {
                value: 3,
                unit: "Hour",
                step: "t3h"
            },
            {
                value: 4,
                unit: "Hour",
                step: "t4h"
            },
            {
                value: 5,
                unit: "Hour",
                step: "t5h"
            },
            {
                value: 6,
                unit: "Hour",
                step: "t6h"
            },
            {
                value: 7,
                unit: "Hour",
                step: "t7h"
            },
            {
                value: 8,
                unit: "Hour",
                step: "t8h"
            },
        ]
    },
    IntraDay3: {
        charts: [
            {
                value: 9,
                unit: "Hour",
                step: "t9h"
            },
            {
                value: 10,
                unit: "Hour",
                step: "t10h"
            },
            {
                value: 11,
                unit: "Hour",
                step: "t11h"
            },
            {
                value: 12,
                unit: "Hour",
                step: "t12h"
            },
            {
                value: 13,
                unit: "Hour",
                step: "t13h"
            },
            {
                value: 14,
                unit: "Hour",
                step: "t14h"
            },
            {
                value: 15,
                unit: "Hour",
                step: "t15h"
            },
            {
                value: 16,
                unit: "Hour",
                step: "t16h"
            },
            {
                value: 17,
                unit: "Hour",
                step: "t17h"
            },
            {
                value: 18,
                unit: "Hour",
                step: "t18h"
            },
        ]
    },
    IntraDay4: {
        charts: [
            {
                value: 19,
                unit: "Hour",
                step: "t19h"
            },
            {
                value: 20,
                unit: "Hour",
                step: "t20h"
            },
            {
                value: 21,
                unit: "Hour",
                step: "t21h"
            },
            {
                value: 22,
                unit: "Hour",
                step: "t22h"
            },
            {
                value: 23,
                unit: "Hour",
                step: "t23h"
            },
            {
                value: 1,
                unit: "Day",
                step: "t1day"
            },
            {
                value: 1.5,
                unit: "Day",
                step: "t36h"
            },
            {
                value: 2,
                unit: "Day",
                step: "t2day"
            },
            {
                value: 2.5,
                unit: "Day",
                step: "t60h"
            },
            {
                value: 3,
                unit: "Day",
                step: "t3day"
            },
        ]
    },
    ShortTerm: {
        charts: [
            {
                value: 4,
                unit: "Day",
                step: "t4day"
            },
            {
                value: 5,
                unit: "Day",
                step: "t5day"
            },
            {
                value: 1,
                unit: "Week",
                step: "t1week"
            },
            {
                value: 6,
                unit: "Day",
                step: "t6day"
            },
            {
                value: 7,
                unit: "Day",
                step: "t7day"
            },
            {
                value: 8,
                unit: "Day",
                step: "t8day"
            },
            {
                value: 9,
                unit: "Day",
                step: "t9day"
            },
            {
                value: 10,
                unit: "Day",
                step: "t10day"
            },
            {
                value: 11,
                unit: "Day",
                step: "t11day"
            },
            {
                value: 12,
                unit: "Day",
                step: "t12day"
            },
        ]
    },
    MidTerm: {
        charts: [
            {
                value: 13,
                unit: "Day",
                step: "t13day"
            },
            {
                value: 14,
                unit: "Day",
                step: "t14day"
            },
            {
                value: 15,
                unit: "Day",
                step: "t15day"
            },
            {
                value: 16,
                unit: "Day",
                step: "t16day"
            },
            {
                value: 17,
                unit: "Day",
                step: "t17day"
            },
            {
                value: 18,
                unit: "Day",
                step: "t18day"
            },
            {
                value: 19,
                unit: "Day",
                step: "t19day"
            },
            {
                value: 20,
                unit: "Day",
                step: "t20day"
            },
            {
                value: 21,
                unit: "Day",
                step: "t21day"
            },
            {
                value: 22,
                unit: "Day",
                step: "t22day"
            },
        ]
    },
    LongTerm1: {
        charts: [
            {
                value: 23,
                unit: "Day",
                step: "t23day"
            },
            {
                value: 24,
                unit: "Day",
                step: "t24day"
            },
            {
                value: 25,
                unit: "Day",
                step: "t25day"
            },
            {
                value: 26,
                unit: "Day",
                step: "t26day"
            },
            {
                value: 27,
                unit: "Day",
                step: "t27day"
            },
            {
                value: 28,
                unit: "Day",
                step: "t28day"
            },
            {
                value: 29,
                unit: "Day",
                step: "t29day"
            },
            {
                value: 30,
                unit: "Day",
                step: "t30day"
            },
            {
                value: 1,
                unit: "Month",
                step: "t1m"
            },
            {
                value: 1.5,
                unit: "Month",
                step: "t45day"
            },
        ]
    },
    LongTerm2: {
        charts: [
            {
                value: 2,
                unit: "Month",
                step: "t60day"
            },
            {
                value: 2.5,
                unit: "Month",
                step: "t75day"
            },
            {
                value: 3,
                unit: "Month",
                step: "t3m"
            },
            {
                value: 4,
                unit: "Month",
                step: "t120day"
            },
            {
                value: 5,
                unit: "Month",
                step: "t150day"
            },
            {
                value: 6,
                unit: "Month",
                step: "t6m"
            },
            {
                value: 7,
                unit: "Month",
                step: "t210day"
            },
            {
                value: 8,
                unit: "Month",
                step: "t240day"
            },
            {
                value: 9,
                unit: "Month",
                step: "t270day"
            },
            {
                value: 10,
                unit: "Month",
                step: "t300day"
            },
        ]
    },
    LongTerm3: {
        charts: [
            {
                value: 11,
                unit: "Month",
                step: "t330day"
            },
            {
                value: 1,
                unit: "Year",
                step: "t1y"
            },
            {
                value: 1.5,
                unit: "Year",
                step: "t18m"
            },
            {
                value: 2,
                unit: "Year",
                step: "t2y"
            },
        ]
    },
    IntraDayShortTerm: {
        charts: [
            {
                value: 1,
                unit: "Minute",
                step: "t1min"
            },
            {
                value: 2,
                unit: "Minute",
                step: "t2min"
            },
            {
                value: 5,
                unit: "Minute",
                step: "t5min"
            },
            {
                value: 10,
                unit: "Minute",
                step: "t10min"
            },
            {
                value: 15,
                unit: "Minute",
                step: "t15min"
            },
            {
                value: 20,
                unit: "Minute",
                step: "t20min"
            },
            {
                value: 30,
                unit: "Minute",
                step: "t30min"
            },
            {
                value: 45,
                unit: "Minute",
                step: "t45min"
            },
            {
                value: 1,
                unit: "Hour",
                step: "t1h"
            },
            {
                value: 2,
                unit: "Hour",
                step: "t2h"
            },
        ]
    },
    FourHourSignals: {
        charts: [
            {
                value: 15,
                unit: "Minute",
                step: "t15min"
            },
            {
                value: 30,
                unit: "Minute",
                step: "t30min"
            },
            {
                value: 1,
                unit: "Hour",
                step: "t1h"
            },
            {
                value: 2,
                unit: "Hour",
                step: "t2h"
            },
            {
                value: 3,
                unit: "Hour",
                step: "t3h"
            },
            {
                value: 4,
                unit: "Hour",
                step: "t4h"
            },
            {
                value: 6,
                unit: "Hour",
                step: "t6h"
            },
            {
                value: 8,
                unit: "Hour",
                step: "t8h"
            },
            {
                value: 10,
                unit: "Hour",
                step: "t10h"
            },
            {
                value: 12,
                unit: "Hour",
                step: "t12h"
            },
        ]
    },
    TwelveHourSignals: {
        charts: [
            {
                value: 4,
                unit: "Hour",
                step: "t4h"
            },
            {
                value: 5,
                unit: "Hour",
                step: "t5h"
            },
            {
                value: 6,
                unit: "Hour",
                step: "t6h"
            },
            {
                value: 8,
                unit: "Hour",
                step: "t8h"
            },
            {
                value: 10,
                unit: "Hour",
                step: "t10h"
            },
            {
                value: 12,
                unit: "Hour",
                step: "t12h"
            },
            {
                value: 14,
                unit: "Hour",
                step: "t14h"
            },
            {
                value: 16,
                unit: "Hour",
                step: "t16h"
            },
            {
                value: 18,
                unit: "Hour",
                step: "t18h"
            },
            {
                value: 1,
                unit: "Day",
                step: "t1day"
            },
        ]
    },
    EighteenHourSignals: {
        charts: [
            {
                value: 8,
                unit: "Hour",
                step: "t8h"
            },
            {
                value: 10,
                unit: "Hour",
                step: "t10h"
            },
            {
                value: 12,
                unit: "Hour",
                step: "t12h"
            },
            {
                value: 14,
                unit: "Hour",
                step: "t14h"
            },
            {
                value: 16,
                unit: "Hour",
                step: "t16h"
            },
            {
                value: 18,
                unit: "Hour",
                step: "t18h"
            },
            {
                value: 20,
                unit: "Hour",
                step: "t20h"
            },
            {
                value: 22,
                unit: "Hour",
                step: "t22h"
            },
            {
                value: 1,
                unit: "Day",
                step: "t1day"
            },
            {
                value: 2,
                unit: "Day",
                step: "t2day"
            },
        ]
    },
    DailySignals: {
        charts: [
            {
                value: 6,
                unit: "Hour",
                step: "t6h"
            },
            {
                value: 8,
                unit: "Hour",
                step: "t8h"
            },
            {
                value: 10,
                unit: "Hour",
                step: "t10h"
            },
            {
                value: 12,
                unit: "Hour",
                step: "t12h"
            },
            {
                value: 14,
                unit: "Hour",
                step: "t14h"
            },
            {
                value: 16,
                unit: "Hour",
                step: "t16h"
            },
            {
                value: 18,
                unit: "Hour",
                step: "t18h"
            },
            {
                value: 20,
                unit: "Hour",
                step: "t20h"
            },
            {
                value: 22,
                unit: "Hour",
                step: "t22h"
            },
            {
                value: 1,
                unit: "Day",
                step: "t1day"
            },
        ]
    },
    WeeklySignals: {
        charts: [
            {
                value: 18,
                unit: "Hour",
                step: "t18h"
            },
            {
                value: 20,
                unit: "Hour",
                step: "t20h"
            },
            {
                value: 22,
                unit: "Hour",
                step: "t22h"
            },
            {
                value: 1,
                unit: "Day",
                step: "t1day"
            },
            {
                value: 1.5,
                unit: "Day",
                step: "t36h"
            },
            {
                value: 2,
                unit: "Day",
                step: "t2day"
            },
            {
                value: 2.5,
                unit: "Day",
                step: "t60h"
            },
            {
                value: 3,
                unit: "Day",
                step: "t3day"
            },
            {
                value: 4,
                unit: "Day",
                step: "t4day"
            },
            {
                value: 1,
                unit: "Week",
                step: "t1week"
            },
        ]
    },
    FourteenDaySignals: {
        charts: [
            {
                value: 1,
                unit: "Day",
                step: "t1day"
            },
            {
                value: 2,
                unit: "Day",
                step: "t2day"
            },
            {
                value: 3,
                unit: "Day",
                step: "t3day"
            },
            {
                value: 4,
                unit: "Day",
                step: "t4day"
            },
            {
                value: 1,
                unit: "Week",
                step: "t1week"
            },
            {
                value: 7,
                unit: "Day",
                step: "t7day"
            },
            {
                value: 10,
                unit: "Day",
                step: "t10day"
            },
            {
                value: 14,
                unit: "Day",
                step: "t14day"
            },
            {
                value: 21,
                unit: "Day",
                step: "t21day"
            },
            {
                value: 1,
                unit: "Month",
                step: "t1m"
            },
        ]
    },
    MonthlySignals: {
        charts: [
            {
                value: 1,
                unit: "Week",
                step: "t1week"
            },
            {
                value: 7,
                unit: "Day",
                step: "t7day"
            },
            {
                value: 10,
                unit: "Day",
                step: "t10day"
            },
            {
                value: 14,
                unit: "Day",
                step: "t14day"
            },
            {
                value: 21,
                unit: "Day",
                step: "t21day"
            },
            {
                value: 22,
                unit: "Day",
                step: "t22day"
            },
            {
                value: 24,
                unit: "Day",
                step: "t24day"
            },
            {
                value: 26,
                unit: "Day",
                step: "t26day"
            },
            {
                value: 28,
                unit: "Day",
                step: "t28day"
            },
            {
                value: 1,
                unit: "Month",
                step: "t1m"
            },
        ]
    },
    MidTerm1Signals: {
        charts: [
            {
                value: 1,
                unit: "Week",
                step: "t1week"
            },
            {
                value: 7,
                unit: "Day",
                step: "t7day"
            },
            {
                value: 14,
                unit: "Day",
                step: "t14day"
            },
            {
                value: 21,
                unit: "Day",
                step: "t21day"
            },
            {
                value: 1,
                unit: "Month",
                step: "t1m"
            },
            {
                value: 1.5,
                unit: "Month",
                step: "t45day"
            },
            {
                value: 2,
                unit: "Month",
                step: "t60day"
            },
            {
                value: 2.5,
                unit: "Month",
                step: "t75day"
            },
            {
                value: 3,
                unit: "Month",
                step: "t3m"
            },
            {
                value: 4,
                unit: "Month",
                step: "t120day"
            },
        ]
    },
    MidTerm2Signals: {
        charts: [
            {
                value: 14,
                unit: "Day",
                step: "t14day"
            },
            {
                value: 21,
                unit: "Day",
                step: "t21day"
            },
            {
                value: 22,
                unit: "Day",
                step: "t22day"
            },
            {
                value: 24,
                unit: "Day",
                step: "t24day"
            },
            {
                value: 26,
                unit: "Day",
                step: "t26day"
            },
            {
                value: 28,
                unit: "Day",
                step: "t28day"
            },
            {
                value: 1,
                unit: "Month",
                step: "t1m"
            },
            {
                value: 2,
                unit: "Month",
                step: "t60day"
            },
            {
                value: 3,
                unit: "Month",
                step: "t3m"
            },
            {
                value: 4,
                unit: "Month",
                step: "t120day"
            },
        ]
    },
    LongTermSecond: {
        charts: [
            {
                value: 1,
                unit: "Month",
                step: "t1m"
            },
            {
                value: 2,
                unit: "Month",
                step: "t60day"
            },
            {
                value: 3,
                unit: "Month",
                step: "t3m"
            },
            {
                value: 4,
                unit: "Month",
                step: "t120day"
            },
            {
                value: 5,
                unit: "Month",
                step: "t150day"
            },
            {
                value: 6,
                unit: "Month",
                step: "t6m"
            },
            {
                value: 7,
                unit: "Month",
                step: "t210day"
            },
            {
                value: 8,
                unit: "Month",
                step: "t240day"
            },
            {
                value: 10,
                unit: "Month",
                step: "t300day"
            },
            {
                value: 1,
                unit: "Year",
                step: "t1y"
            },
        ]
    },
}

function createPeriodTitle(name, index) {
    const o = periods[name]["charts"][index];
    return `${o.value} ${o.unit.toLowerCase()}${o.value > 1 ? 's' : ''}`;
}

function createChartTitle(name, index) {
    const o = periods[name]["charts"][index];
    return `Chart_${o.step}`;
}

export class PeriodsCommon extends React.Component {
    constructor(props) {
        super(props);
        const dashboardChartCount = periods[this.props.period].charts.length;
        let columns;
        if (dashboardChartCount === 8) {
            columns = [
                {
                    type: "column",
                    width: 25,
                    content: [
                        {
                            type: "row",
                            height: 45,
                            content: [
                                {
                                    title: createPeriodTitle(this.props.period, 0),
                                    type: "react-component",
                                    component: createChartTitle(this.props.period, 0),
                                    isClosable: false
                                }
                            ]
                        },
                        {
                            type: "row",
                            height: 55,
                            content: [
                                {
                                    title: createPeriodTitle(this.props.period, 4),
                                    type: "react-component",
                                    component: createChartTitle(this.props.period, 4),
                                    isClosable: false,
                                    popout: false
                                }
                            ]
                        }
                    ]
                },
                {
                    type: "column",
                    width: 25,
                    content: [
                        {
                            type: "row",
                            height: 45,
                            content: [
                                {
                                    title: createPeriodTitle(this.props.period, 1),
                                    type: "react-component",
                                    component: createChartTitle(this.props.period, 1),
                                    isClosable: false
                                }
                            ]
                        },
                        {
                            type: "row",
                            height: 55,
                            content: [
                                {
                                    title: createPeriodTitle(this.props.period, 5),
                                    type: "react-component",
                                    component: createChartTitle(this.props.period, 5),
                                    isClosable: false
                                }
                            ]
                        }
                    ]
                },
                {
                    type: "column",
                    width: 25,
                    content: [
                        {
                            type: "row",
                            height: 45,
                            content: [
                                {
                                    title: createPeriodTitle(this.props.period, 2),
                                    type: "react-component",
                                    component: createChartTitle(this.props.period, 2),
                                    isClosable: false
                                }
                            ]
                        },
                        {
                            type: "row",
                            height: 55,
                            content: [
                                {
                                    title: createPeriodTitle(this.props.period, 6),
                                    type: "react-component",
                                    component: createChartTitle(this.props.period, 6),
                                    isClosable: false
                                }
                            ]
                        }
                    ]
                },
                {
                    type: "column",
                    width: 25,
                    content: [
                        {
                            type: "row",
                            height: 45,
                            content: [
                                {
                                    title: createPeriodTitle(this.props.period, 3),
                                    type: "react-component",
                                    component: createChartTitle(this.props.period, 3),
                                    isClosable: false
                                }
                            ]
                        },
                        {
                            type: "row",
                            height: 55,
                            content: [
                                {
                                    title: createPeriodTitle(this.props.period, 7),
                                    type: "react-component",
                                    component: createChartTitle(this.props.period, 7),
                                    isClosable: false
                                }
                            ]
                        }
                    ]
                },
            ];
        } else if (dashboardChartCount === 10) {
            columns = [
                {
                    type: "column",
                    width: 20,
                    content: [
                        {
                            type: "row",
                            height: 45,
                            content: [
                                {
                                    title: createPeriodTitle(this.props.period, 0),
                                    type: "react-component",
                                    component: createChartTitle(this.props.period, 0),
                                    isClosable: false
                                }
                            ]
                        },
                        {
                            type: "row",
                            height: 55,
                            content: [
                                {
                                    title: createPeriodTitle(this.props.period, 5),
                                    type: "react-component",
                                    component: createChartTitle(this.props.period, 5),
                                    isClosable: false,
                                    popout: false
                                }
                            ]
                        }
                    ]
                },
                {
                    type: "column",
                    width: 20,
                    content: [
                        {
                            type: "row",
                            height: 45,
                            content: [
                                {
                                    title: createPeriodTitle(this.props.period, 1),
                                    type: "react-component",
                                    component: createChartTitle(this.props.period, 1),
                                    isClosable: false
                                }
                            ]
                        },
                        {
                            type: "row",
                            height: 55,
                            content: [
                                {
                                    title: createPeriodTitle(this.props.period, 6),
                                    type: "react-component",
                                    component: createChartTitle(this.props.period, 6),
                                    isClosable: false
                                }
                            ]
                        }
                    ]
                },
                {
                    type: "column",
                    width: 20,
                    content: [
                        {
                            type: "row",
                            height: 45,
                            content: [
                                {
                                    title: createPeriodTitle(this.props.period, 2),
                                    type: "react-component",
                                    component: createChartTitle(this.props.period, 2),
                                    isClosable: false
                                }
                            ]
                        },
                        {
                            type: "row",
                            height: 55,
                            content: [
                                {
                                    title: createPeriodTitle(this.props.period, 7),
                                    type: "react-component",
                                    component: createChartTitle(this.props.period, 7),
                                    isClosable: false
                                }
                            ]
                        }
                    ]
                },
                {
                    type: "column",
                    width: 20,
                    content: [
                        {
                            type: "row",
                            height: 45,
                            content: [
                                {
                                    title: createPeriodTitle(this.props.period, 3),
                                    type: "react-component",
                                    component: createChartTitle(this.props.period, 3),
                                    isClosable: false
                                }
                            ]
                        },
                        {
                            type: "row",
                            height: 55,
                            content: [
                                {
                                    title: createPeriodTitle(this.props.period, 8),
                                    type: "react-component",
                                    component: createChartTitle(this.props.period, 8),
                                    isClosable: false
                                }
                            ]
                        }
                    ]
                },
                {
                    type: "column",
                    width: 20,
                    content: [
                        {
                            type: "row",
                            height: 45,
                            content: [
                                {
                                    title: createPeriodTitle(this.props.period, 4),
                                    type: "react-component",
                                    component: createChartTitle(this.props.period, 4),
                                    isClosable: false
                                }
                            ]
                        },
                        {
                            type: "row",
                            height: 55,
                            content: [
                                {
                                    title: createPeriodTitle(this.props.period, 9),
                                    type: "react-component",
                                    component: createChartTitle(this.props.period, 9),
                                    isClosable: false
                                }
                            ]
                        }
                    ]
                },
            ];
        } else if (dashboardChartCount === 4) {
            columns = [
                {
                    type: "column",
                    width: 50,
                    content: [
                        {
                            type: "row",
                            height: 45,
                            content: [
                                {
                                    title: createPeriodTitle(this.props.period, 0),
                                    type: "react-component",
                                    component: createChartTitle(this.props.period, 0),
                                    isClosable: false
                                }
                            ]
                        },
                        {
                            type: "row",
                            height: 55,
                            content: [
                                {
                                    title: createPeriodTitle(this.props.period, 2),
                                    type: "react-component",
                                    component: createChartTitle(this.props.period, 2),
                                    isClosable: false,
                                    popout: false
                                }
                            ]
                        }
                    ]
                },
                {
                    type: "column",
                    width: 50,
                    content: [
                        {
                            type: "row",
                            height: 45,
                            content: [
                                {
                                    title: createPeriodTitle(this.props.period, 1),
                                    type: "react-component",
                                    component: createChartTitle(this.props.period, 1),
                                    isClosable: false
                                }
                            ]
                        },
                        {
                            type: "row",
                            height: 55,
                            content: [
                                {
                                    title: createPeriodTitle(this.props.period, 3),
                                    type: "react-component",
                                    component: createChartTitle(this.props.period, 3),
                                    isClosable: false
                                }
                            ]
                        }
                    ]
                },
            ];
        }
        this.defaultConfig = {
            settings:{
                hasHeaders: true,
                constrainDragToContainer: true,
                reorderEnabled: false,
                selectionEnabled: false,
                popoutWholeStack: false,
                blockedPopoutsThrowError: true,
                closePopoutsOnUnload: true,
                showPopoutIcon: false,
                showMaximiseIcon: true,
                showMinimiseIcon: true,
                showCloseIcon: false
            },
            content: [
                {
                    type: "row",
                    content: [
                        /*{
                            type: "column",
                            width: 20,
                            content: [
                                {
                                    type: "row",
                                    height: 45,
                                    content: [
                                        {
                                            title: "Live TV",
                                            type: "react-component",
                                            component: "LiveTV",
                                            isClosable: false
                                        }
                                    ]
                                },
                                {
                                    type: "row",
                                    height: 55,
                                    content: [
                                        {
                                            title: "News Feeds",
                                            type: "react-component",
                                            component: "NewsFeeds",
                                            isClosable: false
                                        }
                                    ]
                                }
                            ]
                        },*/
                        ...columns,
                    ]
                }
            ],
        }
    }

    render() {
        return <LayoutCommon name={`${this.props.period}DashBoard`} config={this.defaultConfig}/>
    }
}