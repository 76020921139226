import {ADD_POPOUT, CLOSE_POPOUT, RESTORE_POPUP_STATES, SAVE_POPUP_STATES } from '../actions/actions'

import { getItem as getSettingsItem } from '../../SettingsUtil'

function getSavedPopupState() {
    let popouts = []
    try {
        const item = getSettingsItem("popouts")
        popouts = JSON.parse(item)

    }
    catch (e) {
        console.log(e)
    }
    console.log(popouts)
    return popouts
}

const initialState = {
    currentId: 1,
    popups: []
}

const popoutReducer = (state = initialState, action) => {
    switch (action.type) {
        case ADD_POPOUT:
            {
                let currentId = state.currentId++
                console.log(currentId)
                action.popup.id = currentId
                return {
                    currentId: state.currentId,
                    popups: state.popups.concat([action.popup])
                }
            }
        case CLOSE_POPOUT:
            return {
                ...state,
                popups: state.popups.filter(w => w.id !== action.popup.id)
            }
        case RESTORE_POPUP_STATES:
            return {
                currentId: (action.popups || []).length + 1,
                popups: action.popups
            }
        case SAVE_POPUP_STATES:
            return {
                ...state
            }
        default:
            return state;
    }
}

export default popoutReducer;